import { getApiConnection } from "./common";
import { AxiosResponse } from "axios";
import { ITrip, IParcel, IDrop } from "./mapper/driver";
import { IOfflineTripParcel } from "pcd_library";

const COLLECTION_URI = 'driver';

export const fetchDriverTripDetails = (): Promise<AxiosResponse<ITrip[]>> => {
    const uri = `${COLLECTION_URI}/trip`;

    return getApiConnection().get(uri);
}

export const fetchDriverExpectedParcels = (): Promise<AxiosResponse<IParcel[]>> => {
    const uri = `${COLLECTION_URI}/parcel`;
    
    return getApiConnection().get(uri);
}

export const fetchDriverDropPoints = (): Promise<AxiosResponse<IDrop[]>> => {
    const uri = `${COLLECTION_URI}/trip/drops`;
    
    return getApiConnection().get(uri);
}

export const updateDriverLeaveParcels = (parcels: IOfflineTripParcel[], reason: string) => {
    const uri = `${COLLECTION_URI}/parcel/leave`;

    return getApiConnection().post(uri, {
        parcels,
        reason,
    })
}