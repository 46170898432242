import { IDropStatus } from "pcd_library";

export interface StatusMap {
  [key: string]: IDropStatus;
}

export const dropIsComplete = (statMap: StatusMap, dropid: string) => {
  if (statMap[dropid]) {
    const status = statMap[dropid];

    return (
      status.scannedAt &&
      status.modifiedAt < status.updatedAt &&
      status.totalParcels === status.scannedParcels
    );
  }

  return false;
};

export const createStatMap = (status: IDropStatus[]): StatusMap => {
  return status.reduce((acc, stat) => {
    return { ...acc, [stat.dropid]: stat };
  }, {});
};
