import DropPointParseException from "./exceptions/DropPointParseException";

export const dropPointRegex = /^\s*([zZ]{3})\s*$|^\s*([\d\w]{3})(?:\s*((?:(?:(?:[gG][iI][rR])|(?:[a-pA-Pr-uR-UwyzWYZ])(?:(?:[0-9](?:[a-hA-HjkpstuwJKPSTUW]|[0-9])?)|(?:[a-hA-Hk-yK-Y][0-9](?:[0-9]|[abehmnprABEHMNPRv-yV-Y])?))))\s*(?:[0-9][abABd-hD-HjlnJLNp-uP-Uw-zW-Z]{2})))?(?:$|\s+$)/i;

export class DropPoint {
    private _drop:string;
    private _postcode: string;
    private _exceptional: boolean;

    constructor(drop: string, postcode: string, isException = false) {
        this._drop = this.upperCaseMatch(drop);
        this._postcode = this.upperCaseMatch(postcode);
        this._exceptional = isException;
    }

    static parse(label: string) {
        if(!dropPointRegex.test(label)) {
            throw new DropPointParseException('Drop Point is not the correct format');
        }

        const matches = label.match(dropPointRegex);

        if (!matches) {
            throw new DropPointParseException('Drop Point could not be read correctly');
        }

        const [, exception, drop, postcode] = matches;

        return exception ? 
            new DropPoint(exception, 'Unkown Location', true):
            new DropPoint(drop, postcode);

    }

    private upperCaseMatch(value: string | undefined): string {
        return value && value.toUpperCase() || '';
    }

    get drop(): string {
        return this._drop
    }

    get postcode(): string {
        return this._postcode;
    }

    get isException(): boolean {
        return this._exceptional;
    }
}