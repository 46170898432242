import { updateApiAuthorisation, getApiConnection } from "./common";
import { ServiceWorkerActions } from "pcd_library";

const USER_URI = "user";

function stripTokenData(token: string): object | null {
  if (token.length < 1) {
    return null;
  }

  const pieces = token.split(".");

  if (pieces.length < 2) {
    return null;
  }

  try {
    return JSON.parse(atob(pieces[1]));
  } catch {
    console.error("Failed to parse token response");
    return null;
  }
}

export const authenticateUser = (userId: string) => {
  const url = `${USER_URI}/authenticate`;

  return getApiConnection()
    .post(url, {
      userId,
    })
    .then((response) => {
      // check if it was a real success
      // record the JWT that was returned
      // pass the JWT to the common update
      updateApiAuthorisation(response.data.token);

      response.data.token = stripTokenData(response.data.token);

      return response;
    });
};

export const authenticateUserAs = (userId: string) => {
  const url = `${USER_URI}/authenticateAs`;

  return getApiConnection()
    .post(url, {
      userId,
    })
    .then((response) => {
      // check if it was a real success
      // record the JWT that was returned
      // pass the JWT to the common update
      updateApiAuthorisation(response.data.token);

      response.data.token = stripTokenData(response.data.token);

      return response;
    });
};

export const authenticateServiceWorker = () => {
  const url = `${USER_URI}/authenticate/serviceworker`;

  return getApiConnection()
    .post(url)
    .then((response) => {
      navigator.serviceWorker.ready.then((swRegistration) => {
        if (swRegistration.active) {
          swRegistration.active.postMessage({
            type: "token-update",
            token: response.data.token,
          });
        }
      });
    });
};

export const authenticateServiceWorkerActions = (
  actions: ServiceWorkerActions
) => {
  const url = `${USER_URI}/authenticate/serviceworker`;

  return getApiConnection()
    .post(url)
    .then((response) => {
      actions.token = response.data.token;
    });
};

export const checkServerUpdates = (): Promise<boolean> => {
  const url = `${USER_URI}/authenticate/updates`;

  return getApiConnection()
    .get(url)
    .then((response) => {
      return response.data as boolean;
    });
};

export const acknowledgeServerUpdates = () => {
  const url = `${USER_URI}/authenticate/updates`;

  return getApiConnection()
    .post(url)
    .then((response) => {
      return response.data;
    });
};

export const logoutUser = () => {
  // remove auth token
  updateApiAuthorisation("");
};
