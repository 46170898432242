import React, { useEffect } from "react";
import { IUserAuthenitcation } from "../lib/interfaces/IUserAuthentication";
import { Redirect, useHistory, Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useLogger } from "../lib/logger";

export type LogoutProps = {
  pendingScans: number;
  pendingDeliveries: boolean;
  authentication: IUserAuthenitcation;
  onLogout: () => void;
};

const Logout: React.FC<LogoutProps> = (props) => {
  let routerHistory = useHistory();
  const { logger } = useLogger();
  const hasPendingScans = props.pendingScans > 0;
  const hasPendingDeliveries = props.pendingDeliveries;
  const isLoggedIn = props.authentication.isLoggedIn();

  useEffect(() => {
    logger.debug("Section: Entered Collect");
    return () => logger.debug("Section: Exited Collect");
  }, [logger]);

  if (!isLoggedIn) {
    // user is already logged out
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const cancel = () => {
    if (routerHistory.length < 2) {
      // Refreshed on to the logout screen?
      routerHistory.push("/");
    } else {
      routerHistory.goBack();
    }
  };

  const LogoutForm = (
    <>
      <Row className="justify-content-center">
        <Col xs="auto">Are you sure you wish to logout?</Col>
      </Row>
      <Row className="justify-content-center mt-1">
        <Col xs="3">
          <Button variant="secondary" onClick={cancel}>
            No
          </Button>
        </Col>
        <Col xs="3">
          <Button variant="primary" onClick={props.onLogout}>
            Yes
          </Button>
        </Col>
      </Row>
    </>
  );

  let FormContent = LogoutForm;

  const PendingScansForm = (
    <>
      <Alert variant="danger">
        <span className="font-weight-bold">
          Unable to logout as you have pending scans.
        </span>
        <p className="mt-2">
          You may be disconnected from the internet. These should upload as soon
          as a connection is established. Once the pending items have been
          uploaded you will be able to logout
        </p>
        <Link className="nav-link" to="/pending">
          View pending items
        </Link>
      </Alert>
    </>
  );

  const PendingDeliveriesForm = (
    <>
      <Alert variant="danger" className="mb-2">
        <span className="font-weight-bold">
          Logging out is not advised, you have incomplete deliveries.
        </span>
        <p className="mt-2">
          Some parcels have not been scanned off of the van or a drop has not
          been completed.
        </p>
        <Link className="nav-link" to="/trip-summary">
          View delivery summary
        </Link>
      </Alert>
      {LogoutForm}
    </>
  );

  if (hasPendingScans) {
    FormContent = PendingScansForm;
  } else if (hasPendingDeliveries) {
    FormContent = PendingDeliveriesForm;
  }

  return FormContent;
};

export default Logout;
