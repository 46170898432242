import React from "react"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"

import {SCANNER_TYPE} from 'pcd_library';

export type ScanSelectionProps = {
    scanner: SCANNER_TYPE
    onChange: (newScannerType: SCANNER_TYPE, oldScannerType: SCANNER_TYPE) => void;
}

const hideBarcodeOption = process.env.REACT_APP_HIDE_BARCODE === 'true';

const ScanSelection: React.FC<ScanSelectionProps> = (props) => {
    const chooseQR = () => {
        props.onChange(SCANNER_TYPE.QrCode, props.scanner)
    }

    const chooseBar = () => {
        props.onChange(SCANNER_TYPE.Barcode, props.scanner)
    }

    const chooseManual = () => {
        props.onChange(SCANNER_TYPE.Manual, props.scanner)
    }

    const choosePointer = () => {
        props.onChange(SCANNER_TYPE.Pointer, props.scanner)
    }

    const getVariant = (expected: SCANNER_TYPE) => {
        return expected === props.scanner ? 'primary' : 'outline-secondary';
    }

    return (
        <ButtonGroup>
            <Button
                variant={getVariant(SCANNER_TYPE.QrCode)}
                onClick={chooseQR}>
                    QR</Button>
            {!hideBarcodeOption && <Button
                variant={getVariant(SCANNER_TYPE.Barcode)}
                onClick={chooseBar}
            >
                Barcode
            </Button>}
            <Button
                variant={getVariant(SCANNER_TYPE.Pointer)}
                onClick={choosePointer}
            >
                Pointer
            </Button>
            <Button
                variant={getVariant(SCANNER_TYPE.Manual)}
                onClick={chooseManual}
            >
                Manual
            </Button>
        </ButtonGroup>
    );
}

export default ScanSelection;