import { uuid } from "uuidv4";
import Dexie from "dexie";
import "dexie-observable";
import { DropType } from "./ParcelCode";
import { IDatabaseChange } from "dexie-observable/api";
import { IndexLogger } from "./IndexLogger";

export interface ServiceWorkerOption {
  option: string;
  value: any;
}

export type ChangeSubscriber = (
  changes: IDatabaseChange[],
  partial: boolean
) => void;

export enum ScanState {
  Pending = "pending",
  Sending = "sending",
  Sent = "sent",
  Failed = "failed",
}

export interface IScan {
  headerId: number;
  sub: number;
  drop: string;
  type: DropType;
}

export type CampaignContent = {
  headerId: number;
  sub: number;
  scannedAt: string | null;
};

export interface IOfflineScan extends IScan {
  id?: number;
  state: ScanState;
  readAt: Date;
  modifiedAt?: Date;
  modifiedBy?: string;
  submittedAt?: Date;
  responseStatus?: number;
  message?: string;
}

export interface ITripDetails {
  externalId: number;
  name: string;
  drop: string;
  parcels: number;
  dropid: string;
}

export interface IOfflineTripDetails extends ITripDetails {
  id?: number;
  state?: ScanState;
  scannedAt?: Date;
  submittedAt?: Date;
  responseStatus?: number;
  message?: string;
}

export enum TripParcelPriority {
  Standard,
  HighPriority,
}

export interface ITripParcel {
  trip: number;
  qrcode: number;
  sub: number;
  dropPoint: string;
  scannedAt?: Date;
  priority: TripParcelPriority;
}

export interface IOfflineTripParcel extends ITripParcel {
  id?: number;
  state?: ScanState;
  submittedAt?: Date;
  responseStatus?: number;
  message?: string;
}

export interface IDropPoint {
  trip: number;
  drop: string;
  name: string;
  postcode: string;
  parent: string;
}

export interface IOfflineDropPoint extends IDropPoint {
  id?: number;
  scannedAt?: Date;
  state?: ScanState;
  submittedAt?: Date;
  responseStatus?: number;
  message?: string;
}

export interface IDropStatus {
  dropid: string;
  scannedAt?: Date;
  modifiedAt: Date;
  updatedAt: Date;
  scannedParcels: number;
  totalParcels: number;
}

export type CrossIndexedItems<T> = {
  [key: string]: T;
};

// private _campaign: number;
// private _drop: string;
// private _size: string;
// private _count: number;
// private _type: DropType;
// private _headerId: number;
// private _sub: number;

export type MyDexie = Dexie & {
  offlineScans: Dexie.Table<IOfflineScan, number>;
  offlineTrips: Dexie.Table<IOfflineTripDetails, number>;
  offlineParcels: Dexie.Table<IOfflineTripParcel, number>;
  offlineDrops: Dexie.Table<IOfflineDropPoint, number>;
  offlineDropParcels: Dexie.Table<IOfflineTripParcel, number>;
  swOptions: Dexie.Table<ServiceWorkerOption, string>;
  offlineDropsStatus: Dexie.Table<IDropStatus, string>;
};

export default class RecordStore {
  private myUUID: string;
  private myDB: MyDexie;
  private logger: IndexLogger;

  public readonly DATABASE = "PCD";

  constructor(logger: IndexLogger) {
    this.myUUID = uuid();
    this.myDB = this.setupDB();
    this.logger = logger;
  }

  /**
   * Subscribe to the database change event
   *
   * @param subscriber
   *
   * @return unsubscribe callback
   */
  onChanges(subscriber: ChangeSubscriber): () => void {
    this.myDB.on("changes", subscriber);

    return () => this.myDB.on("changes").unsubscribe(subscriber);
  }

  async getPendingScans(): Promise<IOfflineScan[]> {
    const db = await this.getDB();
    return await db.offlineScans
      .where("state")
      .equals(ScanState.Pending)
      .toArray();
  }

  async getTripParcels(): Promise<IOfflineTripParcel[]> {
    const db = await this.getDB();
    return await db.offlineParcels.toArray();
  }

  async getDropPoints(): Promise<IOfflineDropPoint[]> {
    const db = await this.getDB();
    return await db.offlineDrops.toArray();
  }

  async getTripDetails(): Promise<IOfflineTripDetails[]> {
    const db = await this.getDB();
    return await db.offlineTrips.toArray();
  }

  async storeScan(data: IScan, state = ScanState.Pending) {
    const { headerId, sub, drop, type } = data;

    const storable: IOfflineScan = {
      headerId,
      sub,
      drop,
      type,
      state,
      readAt: new Date(),
    };

    const db = await this.getDB();
    return await db.offlineScans.add(storable);
  }

  async storeTripDetails(details: ITripDetails[]) {
    const offlineRecords = details.map<IOfflineTripDetails>((detail) => ({
      ...detail,
      submittedAt: undefined,
      scannedAt: undefined,
    }));
    const db = await this.getDB();

    return await db.offlineTrips.bulkAdd(offlineRecords);
  }

  async storeTripDetailsUpdate(details: ITripDetails[]) {
    const indexedUpdate = RecordStore.indexTrips<IOfflineTripDetails>(
      details.map<IOfflineTripDetails>((detail) => ({
        ...detail,
        submittedAt: undefined,
        scannedAt: undefined,
      }))
    );
    const db = await this.getDB();

    const indexedExisting = RecordStore.indexTrips<IOfflineTripDetails>(
      await db.offlineTrips.toArray()
    );

    this.logger.info("Trip detail update checks", {
      indexedUpdate,
      indexedExisting,
    });

    const operations: {
      updates: IOfflineTripDetails[];
      inserts: IOfflineTripDetails[];
      deletes: number[];
    } = {
      updates: [],
      inserts: [],
      deletes: [],
    };

    Object.keys(indexedUpdate).forEach((key: string) => {
      if (indexedExisting[key]) {
        if (!this.tripsEqual(indexedExisting[key], indexedUpdate[key])) {
          operations.updates.push({
            ...indexedExisting[key],
            name: indexedUpdate[key].name,
            drop: indexedUpdate[key].drop,
            parcels: indexedUpdate[key].parcels,
          });
        }
      } else {
        // new record
        operations.inserts.push(indexedUpdate[key]);
      }
    });

    Object.keys(indexedExisting).forEach((existingKey: string) => {
      const id = indexedExisting[existingKey].id;
      if (!indexedUpdate[existingKey] && id) {
        operations.deletes.push(id);
      }
    });

    this.logger.info("Trips update ops", operations);

    return Promise.all([
      db.offlineTrips.where("id").anyOf(operations.deletes).delete(),
      db.offlineTrips.bulkPut(operations.updates),
      db.offlineTrips.bulkAdd(operations.inserts),
    ]);
  }

  async storeTripParcels(details: ITripParcel[]) {
    const offlineRecords = details.map<IOfflineTripParcel>((detail) => ({
      ...detail,
      submittedAt: detail.scannedAt || undefined,
    }));
    const db = await this.getDB();

    return await db.offlineParcels.bulkAdd(offlineRecords);
  }

  async storeTripParcelsUpdate(details: ITripParcel[]) {
    const indexedUpdate = RecordStore.indexParcels<IOfflineTripParcel>(
      details.map<IOfflineTripParcel>((detail) => ({ ...detail }))
    );
    const db = await this.getDB();

    const indexedExisting = RecordStore.indexParcels<IOfflineTripParcel>(
      await db.offlineParcels.toArray()
    );

    this.logger.info("Parcels update checks", {
      indexedUpdate,
      indexedExisting,
    });

    const operations: {
      updates: IOfflineTripParcel[];
      inserts: IOfflineTripParcel[];
      deletes: number[];
    } = {
      updates: [],
      inserts: [],
      deletes: [],
    };

    Object.keys(indexedUpdate).forEach((key: string) => {
      if (indexedExisting[key]) {
        // has the drop point been changed
        if (!this.parcelEqual(indexedExisting[key], indexedUpdate[key])) {
          operations.updates.push({
            ...indexedExisting[key],
            dropPoint: indexedUpdate[key].dropPoint,
            scannedAt: indexedUpdate[key].scannedAt,
            state: indexedUpdate[key].scannedAt ? ScanState.Sent : undefined,
          });
        }
      } else {
        // new record
        operations.inserts.push(indexedUpdate[key]);
      }
    });

    Object.keys(indexedExisting).forEach((existingKey: string) => {
      const id = indexedExisting[existingKey].id;
      if (!indexedUpdate[existingKey] && id) {
        operations.deletes.push(id);
      }
    });

    this.logger.info("Parcels update ops", operations);

    return Promise.all([
      db.offlineParcels.where("id").anyOf(operations.deletes).delete(),
      db.offlineParcels.bulkPut(operations.updates),
      db.offlineParcels.bulkAdd(operations.inserts),
    ]);
  }

  async storeDropPointsStatus(drops: IDropPoint[]) {
    const ids = Array.from(new Set(drops.map((drop) => drop.parent)));
    const db = await this.getDB();

    await db.offlineDropsStatus
      .where("dropid")
      .anyOf(ids)
      .modify({ modifiedAt: new Date() });

    const keys = await db.offlineDropsStatus
      .where("dropid")
      .anyOf(ids)
      .primaryKeys();

    const missing = ids.filter((id) => keys.indexOf(id) === -1);

    if (missing.length > 0) {
      await db.offlineDropsStatus.bulkAdd(
        missing.map((dropid) => ({
          dropid,
          modifiedAt: new Date(),
          updatedAt: new Date(),
          scannedParcels: 0,
          totalParcels: 0,
        }))
      );
    }
  }

  async storeDropPoints(drops: IDropPoint[]) {
    const offline = drops as IOfflineDropPoint[];
    const db = await this.getDB();

    this.storeDropPointsStatus(offline);

    return await db.offlineDrops.bulkAdd(offline);
  }

  async storeDropPointsUpdate(drops: IDropPoint[]) {
    const offline = drops as IOfflineDropPoint[];
    const db = await this.getDB();

    const indexedUpdate = RecordStore.indexDrops<IOfflineDropPoint>(offline);
    const indexedExisting = RecordStore.indexDrops<IOfflineDropPoint>(
      await db.offlineDrops.toArray()
    );

    this.logger.info("Drop update checks", { indexedUpdate, indexedExisting });

    const operations: {
      updates: IOfflineDropPoint[];
      inserts: IOfflineDropPoint[];
      deletes: number[];
    } = {
      updates: [],
      inserts: [],
      deletes: [],
    };

    Object.keys(indexedUpdate).forEach((key: string) => {
      if (indexedExisting[key]) {
        // has the drop point been changed
        if (!this.dropsEqual(indexedExisting[key], indexedUpdate[key])) {
          operations.updates.push({
            ...indexedExisting[key],
            name: indexedUpdate[key].name,
            postcode: indexedUpdate[key].postcode,
            parent: indexedUpdate[key].parent,
          });
        }
      } else {
        // new record
        operations.inserts.push(indexedUpdate[key]);
      }
    });

    Object.keys(indexedExisting).forEach((existingKey: string) => {
      const { id, scannedAt } = indexedExisting[existingKey];
      if (!indexedUpdate[existingKey] && id && !scannedAt) {
        operations.deletes.push(id);
      }
    });

    this.logger.info("Drops update ops", operations);

    this.storeDropPointsStatus(operations.updates);

    return Promise.all([
      db.offlineDrops.where("id").anyOf(operations.deletes).delete(),
      db.offlineDrops.bulkPut(operations.updates),
      this.storeDropPoints(operations.inserts),
    ]);
  }

  async scannedTripArrival(id: number) {
    const db = await this.getDB();
    const dateNow = new Date();
    return await db.offlineTrips.where("id").equals(id).modify({
      scannedAt: dateNow,
      modifiedAt: dateNow,
      modifiedBy: this.myUUID,
      state: ScanState.Pending,
    });
  }

  async scannedTripParcel(parcel: IOfflineTripParcel) {
    if (!parcel.id) {
      return Promise.reject("Cannot scan parcel without an id");
    }

    const db = await this.getDB();
    const dateNow = new Date();

    return await db.offlineParcels.where("id").equals(parcel.id).modify({
      scannedAt: dateNow,
      modifiedAt: dateNow,
      modifiedBy: this.myUUID,
      state: ScanState.Pending,
    });
  }

  async claimPendingScanRecords(): Promise<IOfflineScan[] | void> {
    return this.myDB
      .transaction("rw!", this.myDB.offlineScans, async () => {
        const expected = await this.myDB.offlineScans
          .where("state")
          .equals(ScanState.Pending)
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          });

        this.logger.info("Marked records with my UUID", {
          myUUID: this.myUUID,
        });

        const myPending = await this.myDB.offlineScans
          .where({
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          })
          .toArray();

        this.logger.info("Retrieved my records", {
          pending: myPending,
          expected,
          actual: myPending.length,
        });

        return myPending;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            error.failedKeys
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async releaseSendingScanRecords(): Promise<boolean | void> {
    this.logger.info("Releasing current records for next wake up");
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineScans, async () => {
        await db.offlineScans
          .where({ state: ScanState.Sending, modifiedBy: this.myUUID })
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Pending,
          });

        return true;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            error.failedKeys
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          error
        );
      });
  }

  async updateScan(
    id: number | undefined,
    changes: Partial<IOfflineScan>
  ): Promise<number> {
    if (!id) {
      this.logger.error("Cannot update a scan without the primary key");
      return 0;
    }
    const db = await this.getDB();

    return db.offlineScans.update(id, changes);
  }

  async updateTrip(
    id: number | undefined,
    changes: Partial<IOfflineTripDetails>
  ): Promise<number> {
    if (!id) {
      this.logger.error("Cannot update a trip without the primary key");
      return 0;
    }

    const db = await this.getDB();

    return db.offlineTrips.update(id, changes);
  }

  async updateParcel(
    id: number | undefined,
    changes: Partial<IOfflineTripParcel>
  ): Promise<number> {
    if (!id) {
      this.logger.error("Cannot update a parcel without the primary key");
      return 0;
    }

    const db = await this.getDB();

    return db.offlineParcels.update(id, changes);
  }

  async claimPendingTripRecords(): Promise<IOfflineTripDetails[] | void> {
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineTrips, async () => {
        await db.offlineTrips.where("state").equals(ScanState.Pending).modify({
          modifiedAt: new Date(),
          modifiedBy: this.myUUID,
          state: ScanState.Sending,
        });

        this.logger.info("Marked records with my UUID", {
          myUUID: this.myUUID,
        });

        const myPending = await db.offlineTrips
          .where({
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          })
          .toArray();

        this.logger.info("Retrieved my records", { myPending });

        return myPending;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async releaseSendingTripRecords(): Promise<boolean | void> {
    this.logger.info("Releasing current trip records for next wake up");
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineTrips, async () => {
        await db.offlineTrips
          .where({ state: ScanState.Sending, modifiedBy: this.myUUID })
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Pending,
          });

        return true;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async claimPendingTripParcelRecords(): Promise<IOfflineTripParcel[] | void> {
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineParcels, async () => {
        await db.offlineParcels
          .where("state")
          .equals(ScanState.Pending)
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          });

        this.logger.info("Marked records with my UUID", {
          myUUID: this.myUUID,
        });

        const myPending = await db.offlineParcels
          .where({
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          })
          .toArray();

        this.logger.info("Retrieved my records", { myPending });

        return myPending;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async releaseSendingTripParcelRecords(): Promise<boolean | void> {
    this.logger.info("Releasing current trip parcel records for next wake up");
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineParcels, async () => {
        await db.offlineParcels
          .where({ state: ScanState.Sending, modifiedBy: this.myUUID })
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Pending,
          });

        return true;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async scannedParentDropPoint(parentId: string) {
    const dateNow = new Date();
    const db = await this.getDB();

    return await db.offlineDrops.where("parent").equals(parentId).modify({
      scannedAt: dateNow,
      modifiedAt: dateNow,
      modifiedBy: this.myUUID,
      state: ScanState.Pending,
    });
  }

  async scannedDropPoints(drops: string[]) {
    const dateNow = new Date();
    const db = await this.getDB();

    return await db.offlineDrops.where("drop").anyOf(drops).modify({
      scannedAt: dateNow,
      modifiedAt: dateNow,
      modifiedBy: this.myUUID,
      state: ScanState.Pending,
    });
  }

  async getDropPointsByParent(parentid: string): Promise<IOfflineDropPoint[]> {
    const db = await this.getDB();
    const records = await db.offlineDrops
      .where("parent")
      .equals(parentid)
      .toArray();

    return records.filter((record) => record.drop !== record.parent);
  }

  async getDropPointById(dropid: string): Promise<IOfflineDropPoint | null> {
    const db = await this.getDB();

    try {
      const point = await db.offlineDrops.get({ drop: dropid });

      return point || null;
    } catch (e) {
      this.logger.error("Cannot find drop point with drop of ", { dropid });
      return null;
    }
  }

  async createOfflineDropParcelsFor(drops: string[]) {
    const db = await this.getDB();
    const parcels = await this.getParcelsByDropPoint(drops);

    const offlineRecords: ITripParcel[] = parcels.map((parcel) => ({
      trip: parcel.trip,
      qrcode: parcel.qrcode,
      sub: parcel.sub,
      dropPoint: parcel.dropPoint,
      scannedAt: undefined,
      priority: parcel.priority,
    }));

    return await db.offlineDropParcels.bulkAdd(offlineRecords);
  }

  async getParcelsByDropPoint(drops: string[]) {
    const db = await this.getDB();
    return await db.offlineParcels.where("dropPoint").anyOf(drops).toArray();
  }

  async getDropPointsParcels(drops: string[]) {
    const db = await this.getDB();
    return await db.offlineDropParcels
      .where("dropPoint")
      .anyOf(drops)
      .toArray();
  }

  // TODO create a status record for every parent drop
  // does this need to check for a parent record existing before update??
  // needs a flag/update modifiedAt for parent id
  // might want to wrap this operation in something that returns the latest result for the parent drop (display can spin till complete then show)
  async updateDropPointCompletion(dropid: string) {
    const db = await this.getDB();
    const point = await db.offlineDrops.get({
      drop: dropid,
      parent: dropid,
    });

    if (point) {
      const points = await this.getDropPointsByParent(point.parent);

      const parcels = await this.getDropPointsParcels(
        points.map((p) => p.drop)
      );

      const result = {
        scannedAt: point.scannedAt,
        totalParcels: parcels.length,
        scannedParcels: 0,
        updatedAt: new Date(),
      };

      parcels.forEach((parcel) => {
        if (parcel.scannedAt) {
          ++result.scannedParcels;
        }
      });

      return await db.offlineDropsStatus.update(dropid, result);
    }

    this.logger.info("Parent drop point not found for status update");
    return 0;
  }

  async getCompletionStatus(dropids: string[]) {
    const db = await this.getDB();

    return await db.offlineDropsStatus.where("dropid").anyOf(dropids).toArray();
  }

  async getAllCompletionStatus() {
    const db = await this.getDB();

    return await db.offlineDropsStatus.toArray();
  }

  async updateAllDropCompletionStatus() {
    const db = await this.getDB();

    const status = await db.offlineDropsStatus
      .filter((record) => record.modifiedAt > record.updatedAt)
      .toArray();

    for (let stat of status) {
      await this.updateDropPointCompletion(stat.dropid);
    }

    const statusResult = await this.getAllCompletionStatus();

    return statusResult;
  }

  async getDropParent(dropid: string) {
    const db = await this.getDB();

    const [childPoint] = await db.offlineDrops
      .where({ drop: dropid })
      .toArray();

    if (childPoint) {
      if (childPoint.parent === dropid) {
        // is the parent
        return childPoint;
      } else {
        const [parent] = await db.offlineDrops
          .where({ drop: childPoint.parent, parent: childPoint.parent })
          .toArray();
        return parent;
      }
    }

    return null;
  }

  async markDropStatus(dropid: string) {
    const db = await this.getDB();

    db.offlineDropsStatus.update(dropid, {
      modifiedAt: new Date(),
    });
  }

  async getDropParcels() {
    const db = await this.getDB();

    return await db.offlineDropParcels.toArray();
  }

  async scannedDropParcel(id: number | undefined) {
    const changes: Partial<IOfflineTripParcel> = {
      scannedAt: new Date(),
      state: ScanState.Pending,
    };

    return await this.updateDropParcel(id, changes);
  }

  async reassignDropParcels(qrcode: number, newDropPoint: string) {
    const db = await this.getDB();

    const originalValues = await db.offlineParcels
      .where("qrcode")
      .equals(qrcode)
      .toArray();

    const offlineRecords: ITripParcel[] = originalValues.map((parcel) => ({
      trip: parcel.trip,
      qrcode: parcel.qrcode,
      sub: parcel.sub,
      dropPoint: newDropPoint,
      scannedAt: undefined,
      priority: parcel.priority,
    }));

    return await db.offlineDropParcels.bulkAdd(offlineRecords);
  }

  async reassignDropParcel(parcelId: number, newDropPoint: string) {
    const db = await this.getDB();

    const originalValues = await db.offlineParcels
      .where("id")
      .equals(parcelId)
      .toArray();

    const offlineRecords: ITripParcel[] = originalValues.map((parcel) => ({
      trip: parcel.trip,
      qrcode: parcel.qrcode,
      sub: parcel.sub,
      dropPoint: newDropPoint,
      scannedAt: undefined,
      priority: parcel.priority,
    }));

    return await db.offlineDropParcels.bulkAdd(offlineRecords);
  }

  async claimPendingDropParcelRecords(): Promise<IOfflineTripParcel[] | void> {
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineDropParcels, async () => {
        await db.offlineDropParcels
          .where("state")
          .equals(ScanState.Pending)
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          });

        this.logger.info("Marked records with my UUID", {
          myUUID: this.myUUID,
        });

        const myPending = await db.offlineDropParcels
          .where({
            modifiedBy: this.myUUID,
            state: ScanState.Sending,
          })
          .toArray();

        this.logger.info("Retrieved my records", { myPending });

        return myPending;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async releaseSendingDropParcelRecords(): Promise<boolean | void> {
    this.logger.info("Releasing current trip parcel records for next wake up");
    const db = await this.getDB();

    return db
      .transaction("rw!", db.offlineDropParcels, async () => {
        await db.offlineDropParcels
          .where({ state: ScanState.Sending, modifiedBy: this.myUUID })
          .modify({
            modifiedAt: new Date(),
            modifiedBy: this.myUUID,
            state: ScanState.Pending,
          });

        return true;
      })
      .catch(
        Dexie.ModifyError,
        (error: { failures: string | any[]; failedKeys: any }) => {
          this.logger.error(
            `Modify error occured, failed to modiy ${error.failures.length}`,
            { keys: error.failedKeys }
          );
        }
      )
      .catch((error: any) => {
        this.logger.error(
          "Critical error during attempt to claim pending records",
          { error }
        );
      });
  }

  async updateDropParcel(
    id: number | undefined,
    changes: Partial<IOfflineTripParcel>
  ): Promise<number> {
    if (!id) {
      this.logger.error("Cannot update a parcel without the primary key");
      return 0;
    }

    const dateNow = new Date();
    const db = await this.getDB();

    const auditChanges = {
      ...changes,
      modifiedAt: dateNow,
      modifiedBy: this.myUUID,
    };

    return await db.offlineDropParcels.update(id, auditChanges);
  }

  async removeTripParcels(ids: number[]) {
    const db = await this.getDB();

    return await db.offlineParcels.bulkDelete(ids);
  }

  async clearDown() {
    const db = await this.getDB();
    return Promise.all([
      db.offlineParcels.clear(),
      db.offlineScans.clear(),
      db.offlineTrips.clear(),
      db.offlineDrops.clear(),
      db.offlineDropParcels.clear(),
      db.offlineDropsStatus.clear(),
    ]);
  }

  async writeServiceWorkerOption<T>(option: string, value: T) {
    const db = await this.getDB();

    return await db.swOptions.put({
      option,
      value,
    });
  }

  async readServiceWorkerOption(option: string) {
    const db = await this.getDB();

    return await db.swOptions.where("option").equals(option).toArray();
  }

  private setupDB(): MyDexie {
    const db = new Dexie(this.DATABASE);

    db.version(1).stores({
      offlineScans:
        "++id, headerId, sub, drop, type, state, readAt, modifiedAt, modifiedBy, submittedAt",
    });

    db.version(2).stores({
      offlineTrips: "++id, name, state, scannedAt, submittedAt",
      offlineParcels:
        "++id, qrcode, state, scannedAt, submittedAt, dropPoint, [modifiedBy+state]",
    });

    db.version(3).stores({
      offlineDrops:
        "++id, trip, name, drop, postcode, parent, state, scannedAt, submittedAt",
      offlineDropParcels:
        "++id, qrcode, state, scannedAt, submittedAt, dropPoint, [modifiedBy+state]",
    });

    db.version(4).stores({
      swOptions: "option",
    });

    db.version(5).stores({
      offlineDropsStatus: "dropid, modifiedAt, updatedAt",
    });

    db.version(6).stores({
      offlineDrops:
        "++id, trip, name, drop, postcode, parent, state, scannedAt, submittedAt,[drop+parent]",
    });

    return db as MyDexie;
  }

  public static createParcelIndex<T extends ITripParcel>(parcel: T): string {
    return `${parcel.qrcode}_${parcel.sub}_${parcel.trip}`;
  }

  public static indexParcels<T extends ITripParcel>(
    parcels: T[]
  ): CrossIndexedItems<T> {
    const result: CrossIndexedItems<T> = {};

    parcels.forEach((parcel) => {
      const key = RecordStore.createParcelIndex<T>(parcel);
      result[key] = parcel;
    });

    return result;
  }

  public static createTripIndex<T extends ITripDetails>(detail: T): string {
    return `${detail.externalId}`;
  }

  public static indexTrips<T extends ITripDetails>(
    details: T[]
  ): CrossIndexedItems<T> {
    const result: CrossIndexedItems<T> = {};

    details.forEach((detail) => {
      const key = RecordStore.createTripIndex<T>(detail);
      result[key] = detail;
    });

    return result;
  }

  public static createDropIndex<T extends IDropPoint>(drop: T): string {
    return drop.drop;
  }

  public static indexDrops<T extends IDropPoint>(
    drops: T[]
  ): CrossIndexedItems<T> {
    const result: CrossIndexedItems<T> = {};

    drops.forEach((drop) => {
      const key = RecordStore.createDropIndex(drop);
      result[key] = drop;
    });

    return result;
  }

  public tripsEqual(tripA: IOfflineTripDetails, tripB: IOfflineTripDetails) {
    return ["name", "depots", "parcels"].every(
      (key: keyof IOfflineTripDetails) => {
        return tripA[key] === tripB[key];
      }
    );
  }

  public dropsEqual(dropA: IOfflineDropPoint, dropB: IOfflineDropPoint) {
    return ["name", "postcode", "parent"].every(
      (key: keyof IOfflineDropPoint) => {
        return dropA[key] === dropB[key];
      }
    );
  }

  public parcelEqual(parcelA: IOfflineTripParcel, parcelB: IOfflineTripParcel) {
    return (
      parcelA["dropPoint"] === parcelB["dropPoint"] &&
      parcelA.scannedAt?.getTime() === parcelB.scannedAt?.getTime()
    );
  }

  private async getDB() {
    if (!this.myDB.isOpen() || this.myDB.hasFailed()) {
      // Try to re-open connection
      await this.myDB.open();
    }

    return this.myDB;
  }
}
