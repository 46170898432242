import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export type LabelCheckAlertProps = {
    isWorking: boolean;
}

const LabelCheckAlert: React.FC<LabelCheckAlertProps> = ({isWorking}) => {
    
    return (<Alert variant={isWorking ? 'warning' : 'success'}>
                    {isWorking && <>
                        <Spinner size="sm" animation="border"/>
                        <span>Checking label</span>
                    </>}
                    {!isWorking && <>
                        <FontAwesomeIcon className="text-success" icon="check-square" size="lg"/>
                        <span>Label scanned</span>
                    </>}
                </Alert>)
}

export default LabelCheckAlert;