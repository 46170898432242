import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConnectionRequiredAlert from "../components/ConnectionRequiredAlert";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import { getCampaign, removeCampaignFromVan } from "../lib/http/collection";
import Spinner from "react-bootstrap/Spinner";
import {
  Code,
  CampaignContent,
  SCANNER_TYPE,
  Settings,
  USER_TYPE,
  regexpLabel,
} from "pcd_library";
import ScanSelection from "../components/ScanSelection";
import Scanners from "../components/scanners/scanners";
import { FetchState } from "../lib/http/common";

import UIfx from "../lib/uifx";
import Button from "react-bootstrap/Button";
import { useLogger } from "../lib/logger";

export type RemovalProps = {
  isOnline: boolean;
  appSettings: Settings;
  userType: USER_TYPE;
  scanFx: UIfx;
  errorFx: UIfx;
};

type ScannableCampaignContent = CampaignContent & {
  scanned?: boolean;
};

const Removal: React.FC<RemovalProps> = (props) => {
  const { appSettings } = props;
  const { logger } = useLogger();

  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [initialScan, setInitialScan] = useState<Code | null>(null);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [campaignReady, setCampaignReady] = useState<boolean>(false);
  const [parcelsRemoveFetch, setParcelsRemoveFetch] = useState<FetchState>(
    FetchState.Init
  );

  const [campaignFetchState, setCampaignFetchState] = useState<FetchState>(
    FetchState.Init
  );

  const [campaignRecords, setCampaignRecords] = useState<
    ScannableCampaignContent[]
  >([]);

  useEffect(() => {
    logger.debug("Section: Entered Removal");
    return () => logger.debug("Section: Exited Removal");
  }, [logger]);

  const fetchDetails = (scan: Code | null) => {
    if (campaignFetchState === FetchState.Request) {
      return;
    }

    if (!scan) {
      return;
    }
    setParcelsRemoveFetch(FetchState.Init);
    setCampaignFetchState(FetchState.Request);
    setInitialScan(scan);
    setAdditionalInfo("");

    getCampaign(scan.headerId)
      .then((response) => {
        props.scanFx.play();
        setCampaignRecords(
          response.data.map((record) => {
            const modRecord: ScannableCampaignContent = { ...record };

            modRecord.scanned = modRecord.sub === scan.sub;

            return modRecord;
          })
        );
        setCampaignFetchState(FetchState.Received);
        setCampaignReady(true);
      })
      .catch((thrown) => {
        props.errorFx.play();
        setCampaignReady(false);
        setInitialScan(null);
        setCampaignFetchState(FetchState.Failed);

        if (thrown.response) {
          logger.error("Removal: Failed to submit parcel label", {
            response: thrown.response,
          });

          let message = thrown.response.statusText;

          switch (thrown.response.status) {
            case 400:
            case 401:
            case 409:
              message = thrown.response.data.message || message;
              break;
          }

          setAdditionalInfo(message);
        } else {
          if (!thrown.status) {
            // generic network issue
            setAdditionalInfo(
              "Unable to connect to server, please check your connection"
            );
          }
        }
      })
      .then(() => {
        // Finally
      });
  };

  const doRemove = async () => {
    try {
      logger.debug("Removal: Attempting removal from van", {
        parcel: initialScan,
      });
      setParcelsRemoveFetch(FetchState.Request);
      await removeCampaignFromVan(
        initialScan?.headerId || 0,
        campaignRecords
          .filter((record) => record.scanned)
          .map((record) => record.sub),
        ""
      );
      logger.debug("Removal: Action complete, campaign removed from van");
      setParcelsRemoveFetch(FetchState.Received);
      setCampaignReady(false);
      setInitialScan(null);
      setAdditionalInfo("");
    } catch (e) {
      setParcelsRemoveFetch(FetchState.Failed);
      setAdditionalInfo("Unable to remove the items selected");
    }
  };

  const processLabel = (label?: string) => {
    if (!label || campaignFetchState === FetchState.Request) {
      return;
    }

    let parcelSetter = parcelScanned;

    if (!initialScan) {
      parcelSetter = fetchDetails;
    }

    setCurrentScanError(null);

    try {
      const parcel = Code.parseLabel(label);
      parcelSetter(parcel);
    } catch (e) {
      props.errorFx.play();
      // @ts-ignore
      setCurrentScanError(e.message);
      // @ts-ignore
      logger.error(`Removal: Failed to parse label: ${e.message}`, {
        // @ts-ignore
        name: e.name,
        // @ts-ignore
        stack: e.stack,
      });
    }
  };

  const parcelScanned = (code: Code) => {
    const records = campaignRecords.slice(0);

    const isFound = records.some((record) => {
      if (record.sub === code.sub) {
        record.scanned = true;
        return true;
      }
      return false;
    });

    isFound ? props.scanFx.play() : props.errorFx.play();

    setCampaignRecords(records);
  };

  const campaignContentList = () => {
    const contents = campaignRecords.map((record) => (
      <ListGroup.Item key={record.sub}>
        <Row>
          <Col xs="4">Parcel {record.sub}</Col>
          <Col xs="6">
            {record.scannedAt ? `Scanned in (${record.scannedAt})` : ""}
          </Col>
          <Col xs="2">
            {record.scanned ? (
              <FontAwesomeIcon
                className="text-success"
                icon="check-square"
                size="lg"
              />
            ) : (
              <FontAwesomeIcon icon={["far", "square"]} size="lg" />
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  const removalStateDisplay = () => {
    return (
      <Alert variant="info">
        <Row>
          {parcelsRemoveFetch === FetchState.Received ? (
            <>
              <Col xs="2">
                <FontAwesomeIcon
                  className="text-success"
                  icon="check-square"
                  size="sm"
                />
              </Col>
              <Col xs="8">Removed parcels</Col>
            </>
          ) : (
            <>
              <Col xs="2">
                <Spinner size="sm" animation="border" />
              </Col>
              <Col xs="8">Removing parcels</Col>
            </>
          )}
        </Row>
      </Alert>
    );
  };

  if (
    parcelsRemoveFetch > FetchState.Init &&
    parcelsRemoveFetch < FetchState.Received
  ) {
    return removalStateDisplay();
  }

  return (
    <ConnectionRequiredAlert
      isConnected={props.isOnline}
      disconnectMessage="You are currently offline, internet connection is required to do the removal"
    >
      {parcelsRemoveFetch > FetchState.Init && removalStateDisplay()}
      <Row className="justify-content-center">
        <Col xs="auto">
          {!campaignReady && (
            <Alert variant="secondary">
              Scan the first parcel to begin removal
            </Alert>
          )}
          {campaignReady && (
            <Alert variant="info">
              Scan all parcels in the list to be removed
            </Alert>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        {additionalInfo && (
          <Col xs="auto">
            <Alert variant="danger">{additionalInfo}</Alert>
          </Col>
        )}
        <Col xs="12">
          {campaignFetchState === FetchState.Request && (
            <div className="center-spinner">
              <Spinner animation="grow" variant="secondary" />
            </div>
          )}
          <Scanners
            scanType={scanType}
            facingMode={appSettings.FacingMode}
            onRead={(type, label) => processLabel(label)}
            userType={props.userType}
            manualPattern={regexpLabel.source}
          />
        </Col>
      </Row>
      {currentScanError && (
        <Row className="justify-content-center">
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        </Row>
      )}
      <Row className="justify-content-center">
        <Col xs="auto">
          <ScanSelection
            scanner={scanType}
            onChange={(newVal) => {
              setScanType(newVal);
            }}
          />
        </Col>
      </Row>
      {campaignReady && (
        <Row className="mt-2">
          <Col xs="12">
            <span>
              Click Done when all parcels to be taken off are scanned{" "}
            </span>
            <Button variant="primary" onClick={() => doRemove()}>
              Done
            </Button>
          </Col>
        </Row>
      )}
      {campaignReady && (
        <Row className="justify-content-center">
          <Col xs="12">{campaignContentList()}</Col>
        </Row>
      )}
    </ConnectionRequiredAlert>
  );
};

export default Removal;
