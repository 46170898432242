import { getApiConnection } from "./common";
import { AxiosResponse } from "axios";
import { CampaignContent } from "pcd_library";

const COLLECTION_URI = 'collection';

/**
 * Get all entries associated with a campaign
 * 
 * @param campaignCollection 
 */
export const getCampaign = (campaignCollection: number): Promise<AxiosResponse<CampaignContent[]>> => {
    const uri = `${COLLECTION_URI}/${campaignCollection}`;

    return getApiConnection().get(uri);
}

export const removeCampaignFromVan = (campaignCollection: number, list: number[], reason: string) => {
    const uri = `${COLLECTION_URI}/${campaignCollection}/remove`;

    return getApiConnection().put(uri, {
        list,
        reason
    });
}