import { SettingsType, GENERIC_FACING_MODE, USER_TYPE, QR_MODE, SCANNER_TYPE } from "./types";

export const LOCAL_STORAGE_SETTINGS = 'PCD_PWA_SETTINGS';

const defaultSettings: SettingsType = {
    'Facing_Mode': GENERIC_FACING_MODE.Environment,
    'QR_MODE': 'environment',
    'User_Type': USER_TYPE.Warehouse,
    'DataFetchStarted': 0,
    'DataFetchComplete': 0,
    'AutoUpdate': false,
    'ScanType': SCANNER_TYPE.QrCode,
    'ActiveDelivery': '',
}

const localStorage = globalThis && globalThis.window && globalThis.window.localStorage;

export class Settings {

    private _settings: SettingsType;
    private _stashTimeout?: number;

    constructor() {
        const rawSettings = (localStorage && localStorage.getItem(LOCAL_STORAGE_SETTINGS)) || null;

        let userSettings = {};

        if (rawSettings) {
            try {
                userSettings = JSON.parse(rawSettings);
            } catch (e) {
                console.error('Unable to recover user settings, corrupted json')
            }
        }

        this._settings = Object.assign({}, defaultSettings, userSettings)
    }

    get FacingMode(): GENERIC_FACING_MODE {
        return this._settings.Facing_Mode;
    }

    set FacingMode(value: GENERIC_FACING_MODE) {
        this._settings.Facing_Mode = value;
        this.storeSettings();
    }

    get QRMode(): QR_MODE {
        return this._settings.QR_MODE
    }

    set QRMode(value: QR_MODE) {
        this._settings.QR_MODE = value;
        this.storeSettings();
    }

    get UserType(): USER_TYPE {
        return this._settings.User_Type;
    }

    set UserType(value: USER_TYPE) {
        this._settings.User_Type = value;
        this.storeSettings();
    }

    get DataFetchStarted(): number {
        return this._settings.DataFetchStarted;
    }

    set DataFetchStarted(value: number) {
        if (this._settings.DataFetchStarted !== value){
            this._settings.DataFetchStarted = value;
            this.storeSettings();
        }
    }

    get DataFetchComplete(): number {
        return this._settings.DataFetchComplete;
    }

    set DataFetchComplete(value: number) {
        if (value !== this._settings.DataFetchComplete) {
            this._settings.DataFetchComplete = value;
            this.storeSettings();
        }
    }

    get AutoUpdate():boolean {
        return this._settings.AutoUpdate;
    }

    set AutoUpdate(value: boolean) {
        this._settings.AutoUpdate = value;
        this.storeSettings();
    }

    get ScanType(): SCANNER_TYPE {
        return this._settings.ScanType
    }

    set ScanType(value: SCANNER_TYPE) {
        if (this._settings.ScanType !== value) {
            this._settings.ScanType = value;
            this.storeSettings();
        }
    }

    get ActiveDelivery(): string {
        return this._settings.ActiveDelivery;
    }

    set ActiveDelivery(value: string) {
        if (this._settings.ActiveDelivery !== value) {
            this._settings.ActiveDelivery = value;
            this.storeSettings();
        }
    }

    private storeSettings() {
        clearTimeout(this._stashTimeout);

        this._stashTimeout = setTimeout(this.writeSettings.bind(this), 1000);
    }

    private writeSettings() {
        try {
            const rawSettings = JSON.stringify(this._settings);
            if (localStorage){
                localStorage.setItem(LOCAL_STORAGE_SETTINGS, rawSettings);
            }
        } catch (e) {
            console.error('Unable to store user settings', e)
        }
    }
}

export const instance = new Settings();

export default {
    LOCAL_STORAGE_SETTINGS,
    USER_TYPE,
    instance,
    SCANNER_TYPE,
    GENERIC_FACING_MODE
}