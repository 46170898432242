import React, { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import { USER_TYPE, SCANNER_TYPE, GENERIC_FACING_MODE } from "pcd_library";
import { IUserAuthenitcation } from "../lib/interfaces/IUserAuthentication";
import { Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import AuthenticationException from "../lib/exceptions/AuthenticationException";
import Button from "react-bootstrap/Button";
import Scanners from "../components/scanners/scanners";
import Form from "react-bootstrap/Form";
import UIfx from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type LoginProps = {
  appSettings: any;
  authentication: IUserAuthenitcation;
  onLogin: (userId: string) => Promise<string>;
  onLoginAs: (userId: string) => Promise<string>;
  scanFx: UIfx;
  errorFx: UIfx;
};

const debugLogin = process.env.REACT_APP_DEBUG_ACCOUNT === "true";

const Login: React.FC<LoginProps> = (props) => {
  const { logger } = useLogger();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [loginError, setLogginError] = useState<string>("");

  useEffect(() => {
    logger.debug("Section: Entered Login");
    return () => logger.debug("Section: Exited Login");
  }, [logger]);

  if (props.authentication.isLoggedIn()) {
    // user already logged in redirect to default
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  if (isLoggingIn) {
    return (
      <div>
        <h2>
          Logging in please wait <Spinner animation="border" />
        </h2>
      </div>
    );
  }

  return (
    <>
      <Alert variant="info">
        Scan the login code in order to setup the device
      </Alert>
      {props.authentication.displayName.length > 0 &&
        !props.authentication.isActive() && (
          <Alert variant="warning">Your login has expired, please login</Alert>
        )}
      {loginError.length > 0 && <Alert variant="danger">{loginError}</Alert>}
      <Scanners
        userType={USER_TYPE.Driver}
        scanType={SCANNER_TYPE.QrCode}
        facingMode={GENERIC_FACING_MODE.Environment}
        onRead={(type: SCANNER_TYPE, label: string) => {
          setIsLoggingIn(true);
          props.onLogin(label).catch((e: AuthenticationException) => {
            props.errorFx.play();
            const message =
              (e.response && e.response.data.message) || e.message;
            setLogginError(message);
            setIsLoggingIn(false);
          });
        }}
      />
      {/* TODO remove the below form */}
      <Form
        className="mt-2"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          event.stopPropagation();

          const field = document.getElementById(
            "formBasicEmail"
          ) as HTMLInputElement;
          const value = field ? field.value : "";

          if (value) {
            setIsLoggingIn(true);
            props.onLogin(value).catch((e: AuthenticationException) => {
              const message =
                (e.response && e.response.data.message) || e.message;
              setLogginError(message);
              setIsLoggingIn(false);
            });
          }
        }}
      >
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Login code</Form.Label>
          <Form.Control type="text" placeholder="stCode from database" />
        </Form.Group>
        <Button type="submit">Login</Button>
      </Form>
      {debugLogin && (
        <Form
          className="mt-2"
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();

            const field = document.getElementById(
              "formBasicEmailDebug"
            ) as HTMLInputElement;
            const value = field ? field.value : "";

            if (value) {
              setIsLoggingIn(true);
              props.onLoginAs(value).catch((e: AuthenticationException) => {
                const message =
                  (e.response && e.response.data.message) || e.message;
                setLogginError(message);
                setIsLoggingIn(false);
              });
            }
          }}
        >
          <Form.Group controlId="formBasicEmailDebug">
            <Form.Label>Debug Login code</Form.Label>
            <Form.Control type="text" placeholder="stCode from database" />
          </Form.Group>
          <Button type="submit">Debug Login</Button>
        </Form>
      )}
    </>
  );
};

export default Login;
