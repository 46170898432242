import React, { useState, useEffect, useCallback } from "react";
import { FetchState } from "../lib/http/common";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scanners from "../components/scanners/scanners";
import {
  SCANNER_TYPE,
  Code,
  USER_TYPE,
  IOfflineTripParcel,
  ScanState,
  regexpLabel,
  IOfflineDropPoint,
  Settings,
} from "pcd_library";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import ScanSelection from "../components/ScanSelection";
import DateFormatter from "../lib/dateFormatter";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import DataFetch from "../components/loadup/DataFetch";
import { ScanStateAction } from "../lib/types/scan";
import UIfx, { FX_PLAY, RETURN_TO_SCAN } from "../lib/uifx";
import { useLogger } from "../lib/logger";

export type LoadupProps = {
  tripFetch: FetchState;
  parcelFetch: FetchState;
  isOnline: boolean;
  appSettings: Settings;
  userType: USER_TYPE;
  parcels: IOfflineTripParcel[];
  parcelScanned: (parcel: IOfflineTripParcel) => void;
  scanFx: UIfx;
  errorFx: UIfx;
  allScanned: boolean;
  destinations: IOfflineDropPoint[];
};

const Loadup: React.FC<LoadupProps> = (props) => {
  const {
    tripFetch,
    parcelFetch,
    isOnline,
    appSettings,
    parcels,
    allScanned,
    destinations,
    parcelScanned,
    scanFx,
    errorFx,
    userType,
  } = props;
  const { logger } = useLogger();
  const [scanType, setScanType] = useState<SCANNER_TYPE>(appSettings.ScanType);
  const [currentScanError, setCurrentScanError] = useState<string | null>(null);
  const [showScanned, setShowScanned] = useState<boolean>(false);

  const [shownItems, setShownItems] = useState<IOfflineTripParcel[]>(parcels);
  const [scanState, setScanState] = useState<ScanStateAction>(
    ScanStateAction.Scanning
  );
  const [currentGroupDestination, setCurrentGroupDestination] =
    useState<IOfflineDropPoint | null>(null);
  const [questionNewDrop, setQuestionNewDrop] =
    useState<IOfflineDropPoint | null>(null);
  const [questionParcel, setQuestionParcel] =
    useState<IOfflineTripParcel | null>(null);
  const [itemsRemaining, setItemsRemaining] = useState<number>(-1);
  const [destinationComplete, setDestinationComplete] = useState<string>("");
  const [scanBuff, setScanBuff] = useState<string[]>([]);

  useEffect(() => {
    logger.debug("Section: Entered Loadup");
    return () => logger.debug("Section: Exited Loadup");
  }, [logger]);

  useEffect(() => {
    logger.debug("Loadup destinations", { destinations });
  }, [destinations, logger]);

  useEffect(() => {
    const acceptableDropPoints = currentGroupDestination
      ? destinations.reduce<{ [key: string]: boolean }>(
          (accum, destination) => {
            if (destination.parent === currentGroupDestination.parent) {
              return {
                ...accum,
                [destination.drop]: true,
              };
            }

            return accum;
          },
          {}
        )
      : {};

    let remaining = 0;

    setShownItems(
      showScanned && !currentGroupDestination
        ? parcels
        : parcels.filter((parcel) => {
            let keepRecord = true;

            if (!showScanned && parcel.scannedAt) {
              keepRecord = false;
            }

            if (
              currentGroupDestination &&
              !acceptableDropPoints[parcel.dropPoint]
            ) {
              keepRecord = false;
            } else if (currentGroupDestination && !parcel.scannedAt) {
              ++remaining;
            }

            return keepRecord;
          })
    );

    setItemsRemaining(remaining);
  }, [parcels, showScanned, currentGroupDestination, destinations]);

  useEffect(() => {
    if (currentGroupDestination && itemsRemaining === 0) {
      setDestinationComplete(currentGroupDestination.parent);
      setItemsRemaining(-1);
      setCurrentGroupDestination(null);
    }
  }, [itemsRemaining, currentGroupDestination]);

  useEffect(() => {
    if (scanState === ScanStateAction.Complete) {
      setTimeout(() => {
        setScanState(ScanStateAction.Scanning);
      }, RETURN_TO_SCAN);
    }
  }, [scanState]);

  const findScannedParcelParentDrop = useCallback(
    (parcel: Code) => {
      const foundDestination = destinations.find(
        (fdestination) => fdestination.drop === parcel.parentDrop
      );

      if (!foundDestination) {
        setCurrentScanError(
          "This parcel does not have a drop point recorded for this trip"
        );
        setScanState(ScanStateAction.Scanning);
      }

      return foundDestination;
    },
    [destinations]
  );

  const makeSwitchToNewDropPoint = () => {
    setCurrentGroupDestination(questionNewDrop);
    if (questionParcel) {
      if (!questionParcel.scannedAt) {
        parcelScanned(questionParcel);
      }
      setScanState(ScanStateAction.Complete);
    }
    setQuestionParcel(null);
    setQuestionNewDrop(null);
  };

  const processLabel = useCallback(
    (label?: string) => {
      if (!label) {
        return;
      }

      if (scanState !== ScanStateAction.Scanning) {
        logger.debug("Loadup: Added scan to buffer", {
          label,
          bufferLength: scanBuff.length,
        });
        setScanBuff((buff: string[]) => buff.concat([label]));
        return;
      }

      setScanState(ScanStateAction.Read);

      setCurrentScanError(null);

      try {
        const parcel = Code.parseLabel(label);

        const found = parcels.find(
          (fparcel) =>
            fparcel.qrcode === parcel.headerId && fparcel.sub === parcel.sub
        );

        if (!found) {
          setCurrentScanError("This parcel is not expected for this trip");
          setScanState(ScanStateAction.Scanning);
        } else {
          if (!currentGroupDestination) {
            const foundDestination = findScannedParcelParentDrop(parcel);

            if (!foundDestination) {
              return;
            }
            setItemsRemaining(-1);
            setCurrentGroupDestination(foundDestination);
          } else if (currentGroupDestination.parent !== parcel.parentDrop) {
            const foundQueryDestination = findScannedParcelParentDrop(parcel);

            if (!foundQueryDestination) {
              return;
            }

            setQuestionNewDrop(foundQueryDestination);
            setQuestionParcel(found);
          }

          scanFx.play();
          if (!found.scannedAt) {
            // report scan to app
            parcelScanned(found);
          }
          setTimeout(() => {
            setScanState(ScanStateAction.Complete);
          }, FX_PLAY);
          return;
        }
      } catch (e) {
        // @ts-ignore
        setCurrentScanError(e.message);
        // @ts-ignore
        logger.error(`Loadup: Failed to parse label: ${e.message}`, {
          // @ts-ignore
          name: e.name,
          // @ts-ignore
          stack: e.stack,
        });
      }

      errorFx.play();
      setTimeout(() => {
        setScanState(ScanStateAction.Scanning);
      }, FX_PLAY);
    },
    [
      currentGroupDestination,
      errorFx,
      findScannedParcelParentDrop,
      parcelScanned,
      parcels,
      scanBuff,
      scanFx,
      scanState,
      logger,
    ]
  );

  useEffect(() => {
    if (scanState === ScanStateAction.Scanning) {
      logger.debug("Loadup: Returned to scanning, check buffer");
      if (scanBuff.length > 0) {
        logger.debug("Loadup: Item in buffer, sending to scanner");
        processLabel(scanBuff[0]);
        setScanBuff((buff) => buff.slice(1));
      }
    }
  }, [scanState, scanBuff, processLabel, logger]);

  if (
    tripFetch !== FetchState.Received ||
    parcelFetch !== FetchState.Received
  ) {
    return <DataFetch tripFetch={tripFetch} parcelFetch={parcelFetch} />;
  }

  if (allScanned) {
    return (
      <Row className="justify-content-center mt-2">
        <Col xs="auto">
          <p>All items for this trip have been scanned</p>
          <Link
            to={{
              pathname: "/trip-details",
            }}
          >
            <Button variant="success">Proceed</Button>
          </Link>
        </Col>
      </Row>
    );
  }

  const parcelIcon = (parcel: IOfflineTripParcel) => {
    let result;

    if (!parcel.scannedAt) {
      result = <FontAwesomeIcon icon={["far", "square"]} size="lg" />;
    } else if (parcel.scannedAt && !parcel.submittedAt) {
      result = (
        <FontAwesomeIcon
          className="text-warning"
          icon="check-square"
          size="lg"
        />
      );
    } else if (parcel.state === ScanState.Failed) {
      result = (
        <FontAwesomeIcon
          className="text-danger"
          icon="times-square"
          size="lg"
        />
      );
    } else if (parcel.submittedAt || parcel.state === ScanState.Sent) {
      result = (
        <FontAwesomeIcon
          className="text-success"
          icon="check-square"
          size="lg"
        />
      );
    }

    return result;
  };

  const listOutParcels = (parcels: IOfflineTripParcel[]) => {
    const contents = parcels.map((record) => (
      <ListGroup.Item key={`${record.id}_${record.qrcode}`}>
        <Row>
          <Col xs="5">
            Parcel {record.qrcode}-{record.sub}
          </Col>
          <Col xs="5">
            {record.scannedAt
              ? `Scanned in (${DateFormatter.humanReadable(record.scannedAt)})`
              : "Not Scanned"}
          </Col>
          <Col xs="2">{parcelIcon(record)}</Col>
        </Row>
      </ListGroup.Item>
    ));

    return <ListGroup className="mt-2">{contents}</ListGroup>;
  };

  return (
    <>
      {/* {!isOnline && (
        <Alert variant="warning">
          You are not currently connected to the internet
        </Alert>
      )} */}
      {!currentGroupDestination && !destinationComplete && (
        <Alert variant="info">
          Please scan a parcel to begin loading for a destination
        </Alert>
      )}
      {!currentGroupDestination && destinationComplete && (
        <Alert variant="success">
          All parcels for <strong>{destinationComplete}</strong>, please scan a
          parcel to begin loading for the next destination
        </Alert>
      )}
      {currentGroupDestination && (
        <Alert variant="info">
          Please scan all parcels for
          <strong> {currentGroupDestination.name}</strong>, {itemsRemaining}
          {itemsRemaining === 1 ? " parcel" : " parcels"} remaining
        </Alert>
      )}
      <Row className="justify-content-center">
        {currentScanError && (
          <Col xs="auto">
            <Alert variant="danger">{currentScanError}</Alert>
          </Col>
        )}
        {scanState !== ScanStateAction.Scanning && (
          <Col xs="auto">
            <Alert
              variant={
                scanState === ScanStateAction.Complete ? "success" : "warning"
              }
            >
              {scanState !== ScanStateAction.Complete && (
                <>
                  <Spinner size="sm" animation="border" />
                  <span>Checking label</span>
                </>
              )}
              {scanState === ScanStateAction.Complete && (
                <>
                  <FontAwesomeIcon
                    className="text-success"
                    icon="check-square"
                    size="lg"
                  />
                  <span>Label scanned</span>
                </>
              )}
            </Alert>
          </Col>
        )}
        {!questionNewDrop && (
          <>
            <Col xs="12">
              <Scanners
                scanType={scanType}
                facingMode={appSettings.FacingMode}
                onRead={(type, label) => processLabel(label)}
                userType={userType}
                manualPattern={regexpLabel.source}
              />
            </Col>
            <Col xs="auto">
              <ScanSelection
                onChange={(newType) => {
                  appSettings.ScanType = newType;
                  setScanType(newType);
                }}
                scanner={scanType}
              />
            </Col>
          </>
        )}
        {questionNewDrop && (
          <Col xs="12">
            <Alert variant="warning">
              All parcels for the current drop point have not been scanned do
              you wish to switch to the next drop point '
              {questionNewDrop.parent}'?
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    setQuestionParcel(null);
                    setQuestionNewDrop(null);
                  }}
                >
                  No
                </Button>
                <Button variant="danger" onClick={makeSwitchToNewDropPoint}>
                  Yes
                </Button>
              </div>
            </Alert>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs="12">&nbsp;</Col>
        <Col xs="auto">
          <Form>
            <Form.Check
              label="Show scanned items"
              checked={showScanned}
              onChange={() => setShowScanned(!showScanned)}
              id="show-processed-items-loadup"
            />
          </Form>
          {process.env.REACT_APP_HIDE_LOADALL !== "true" && (
            <Button
              onClick={() => {
                let lastPromise = Promise.resolve();
                shownItems.forEach((parcel) => {
                  lastPromise = lastPromise.finally(() => {
                    return new Promise((resolve) => {
                      parcelScanned(parcel);
                      setTimeout(resolve, 500);
                    });
                  });
                });
              }}
            >
              Scan all
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12">{listOutParcels(shownItems)}</Col>
      </Row>
      <br />
    </>
  );
};

export default Loadup;
