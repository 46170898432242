export type QR_MODE = 'user' | 'environment' | undefined;

export enum USER_TYPE {
    None = "NONE",
    Courier = "Courier",
    Driver = "Driver",
    Warehouse = "Warehouse"
}

export enum SCANNER_TYPE {
    QrCode,
    Barcode,
    Manual,
    Pointer,
}

export enum GENERIC_FACING_MODE {
    User,
    Environment
}

export type SettingsType = {
    QR_MODE: QR_MODE;
    User_Type: USER_TYPE;
    Facing_Mode: GENERIC_FACING_MODE,
    DataFetchStarted: number;
    DataFetchComplete: number;
    AutoUpdate: boolean;
    ScanType: SCANNER_TYPE;
    ActiveDelivery: string;
}