import React from "react";
import {
  SCANNER_TYPE,
  GENERIC_FACING_MODE,
  QR_MODE,
  USER_TYPE,
} from "pcd_library";
import QrReader from "react-qr-reader";
import Barcode from "./barcode";
import Manual from "./manual";
import Pointer from "./pointer";
import { useLogger } from "../../lib/logger";

export type ScannersProps = {
  scanType: SCANNER_TYPE;
  facingMode: GENERIC_FACING_MODE;
  onRead: (type: SCANNER_TYPE, label: string) => void;
  userType: USER_TYPE;
  manualPattern?: string;
  manualExpected?: string;
  manualInstruction?: string;
  manualPlaceholder?: string;
};

const Scanners: React.FC<ScannersProps> = (props) => {
  const { logger } = useLogger();
  const { scanType, facingMode } = props;

  let QrMode: QR_MODE = "environment";
  let BarMode: string = "environment";

  if (facingMode === GENERIC_FACING_MODE.User) {
    QrMode = "user";
    BarMode = "user";
  }

  const handleLabelCheck = (
    result: string,
    type: SCANNER_TYPE = SCANNER_TYPE.Manual
  ) => {
    logger.debug("Scanner: Read a label", { type, result });
    props.onRead(type, result);
  };

  return (
    <div className="scanners">
      {scanType === SCANNER_TYPE.QrCode && (
        <QrReader
          delay={400}
          onError={(...args) =>
            logger.error("Scanner: error occurred", { args })
          }
          onScan={(result) =>
            result && handleLabelCheck(result, SCANNER_TYPE.QrCode)
          }
          facingMode={QrMode}
          className="mb-2"
        />
      )}
      {scanType === SCANNER_TYPE.Barcode && (
        <Barcode
          facingMode={BarMode}
          onScan={(result) => handleLabelCheck(result, SCANNER_TYPE.Barcode)}
        />
      )}
      {scanType === SCANNER_TYPE.Pointer && (
        <Pointer
          onSubmit={handleLabelCheck}
          userType={props.userType}
          pattern={props.manualPattern + ".*"}
          expected={props.manualExpected}
        />
      )}
      {scanType === SCANNER_TYPE.Manual && (
        <Manual
          onSubmit={handleLabelCheck}
          userType={props.userType}
          pattern={props.manualPattern}
          expected={props.manualExpected}
          instruction={props.manualInstruction}
          placeholder={props.manualPlaceholder}
        />
      )}
    </div>
  );
};

export default Scanners;
