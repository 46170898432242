import {
  IUserAuthenitcation,
  AuthenticationDetails,
} from "./interfaces/IUserAuthentication";
import { USER_TYPE } from "pcd_library";

const AUTH_DETAILS = "auth-details";

export default class Authentication implements IUserAuthenitcation {
  private myRole: USER_TYPE = USER_TYPE.None;
  private myLoggedIn: boolean = false;
  private myExpire: number = 0;
  private myDisplayName: string = "";

  constructor(details?: AuthenticationDetails) {
    if (details) {
      this.myRole = details.role;
      this.myLoggedIn = details.loggedIn || !!details.userId;
      this.myExpire = details.expire;
      this.myDisplayName = details.displayName || details.role;
    }
  }

  get role(): USER_TYPE {
    return this.myRole;
  }

  get displayName(): string {
    return this.myDisplayName;
  }

  isLoggedIn(): boolean {
    return this.myLoggedIn && this.isActive();
  }

  isActive(): boolean {
    const currentDate = new Date().getTime() / 1000;

    return this.myExpire > 0 && this.myExpire > currentDate;
  }

  toJSON(): AuthenticationDetails {
    return {
      role: this.myRole,
      loggedIn: this.myLoggedIn,
      expire: this.myExpire,
      userId: undefined,
      displayName: this.myDisplayName,
    };
  }

  storeUserAuthentication() {
    localStorage.setItem(AUTH_DETAILS, JSON.stringify(this));
  }

  static retrieveUserAuthentication(): Authentication {
    try {
      const sessionInfo = sessionStorage.getItem(AUTH_DETAILS);
      const rawDetails = sessionInfo || localStorage.getItem(AUTH_DETAILS);

      if (rawDetails) {
        const details = JSON.parse(rawDetails);
        if (details) {
          const auth = new Authentication(details);

          // Details have been copied over, store properly then clear session
          if (sessionInfo) {
            auth.storeUserAuthentication();
            sessionStorage.setItem(AUTH_DETAILS, "");
          }

          return auth;
        }
      }
    } catch (e) {
      console.error("Failed to restore authentication", e);
    }

    return new Authentication();
  }

  static clearUserAuthentication() {
    sessionStorage.setItem(AUTH_DETAILS, "");
    localStorage.setItem(AUTH_DETAILS, "");
  }
}
