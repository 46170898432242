import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import { USER_TYPE } from "pcd_library";

import "./pointer.scss";
import Spinner from "react-bootstrap/Spinner";
import { useLogger } from "../../lib/logger";

export type PointerProps = {
  onSubmit: (submission: string) => void;
  userType: USER_TYPE;
  pattern?: string;
  expected?: string;
};

const pointerFieldId = "pointer_label_input";

const Pointer: React.FC<PointerProps> = (props) => {
  const { logger } = useLogger();
  const [validated, setValidated] = useState(false);
  const [latestValue, setLatestValue] = useState<string>("");
  const [isReading, setIsReading] = useState(false);

  useEffect(() => {
    const currentLength = latestValue.length;
    if (!isReading && currentLength > 0) {
      setIsReading(true);
    } else if (isReading && currentLength === 0) {
      setIsReading(false);
    }
  }, [latestValue, isReading]);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);

    if (event.currentTarget.checkValidity() === false) {
      logger.warn("Pointer: Invalid submission of", { latestValue });
      setLatestValue("");
      return;
    }

    logger.info("Pointer: Submission of", { latestValue });
    props.onSubmit(latestValue);
    setLatestValue("");
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLatestValue(event.target.value);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={submitHandler}
      onClick={() => {
        const inp = document.getElementById(pointerFieldId);

        if (inp) {
          inp.focus();
        }
      }}
      className="pointer_form"
    >
      <Row>
        <Col sm={12} md={6}>
          <Form.Group controlId={pointerFieldId}>
            <Form.Label>Scan items using a scanning device</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Package label"
              onChange={changeHandler}
              isValid={false}
              autoFocus={true}
              // onFocus={() => window.clearTimeout(blurTimeout)}
              // onBlur={(ev:React.FocusEvent<HTMLInputElement>) => {
              // const target = ev.currentTarget;
              // setBlurTimeout(window.setTimeout(() => return; target.focus(), 100));}}
              className="hidden_scan_input"
              value={latestValue}
              autoComplete="off"
            />
            <div className="spinner">
              {!isReading && <Spinner animation="border" />}
              {isReading && (
                <span>
                  reading code <Spinner size="sm" animation="border" />
                </span>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col sm={12} md={6} className="emergency_wrapper">
          <Button variant="primary" className="emergency_focus">
            Click here when ready to scan
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Pointer;
