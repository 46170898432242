import React, { useEffect, useRef } from "react";
import Quagga from "quagga-scanner";

import "./barcode.scss";
import { useLogger } from "../../lib/logger";

export type BarcodeProps = {
  facingMode?: string;
  onScan: (result: string) => void;
};

const Barcode: React.FC<BarcodeProps> = (props) => {
  const { logger } = useLogger();
  const scannerWindow = useRef<HTMLDivElement>(null);

  const { facingMode, onScan } = props;

  useEffect(() => {
    if (!scannerWindow.current) {
      return;
    }

    Quagga.init(
      {
        locate: true,
        debug: true,
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: scannerWindow.current,
          constraints: {
            facingMode,
          },
        },
        decoder: {
          readers: ["code_128_reader"],
          debug: {
            drawBoundingBox: true,
            showFrequency: false,
            drawScanline: true,
            showPattern: true,
          },
        },
        locator: {
          halfSample: true,
          patchSize: "x-small", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: true,
            showFoundPatches: true,
            showSkeleton: false,
            showLabels: true,
            showPatchLabels: true,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
      },
      function (err) {
        if (err) {
          logger.error("Barcode scan error", { err });
          return;
        }
        logger.debug("Barcode: Initialization finished. Ready to start");
        Quagga.start();
      }
    );

    Quagga.onDetected((...args) => {
      logger.debug("Barcode: Detected", { args });
      onScan(args[0].codeResult.code);
    });

    return Quagga.stop;
  }, [facingMode, onScan, logger]);

  return <div ref={scannerWindow} className="barcode-scanner"></div>;
};

export default Barcode;
