import React from "react";
import Alert from "react-bootstrap/Alert";

export type ConnectionRequiredAlertProps = {
  disconnectMessage: string;
  isConnected: boolean;
  children?: React.ReactNode;
};

const ConnectionRequiredAlert: React.FC<ConnectionRequiredAlertProps> = (
  props
) => {
  if (props.isConnected) {
    return props.children ? <>{props.children}</> : <></>;
  }

  return (
    <Alert className="connection-alert" variant="danger">
      {props.disconnectMessage}
    </Alert>
  );
};

export default ConnectionRequiredAlert;
