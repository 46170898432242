import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useLogger } from "../lib/logger";

export type HelpProps = {};

const Help: React.FC<HelpProps> = () => {
  const { logger, download } = useLogger();
  const [extracting, setExtracting] = useState(false);

  useEffect(() => {
    logger.debug("Section: Entered Help");
    return () => logger.debug("Section: Exited Help");
  }, [logger]);

  const handleDownloadLog = () => {
    setExtracting(true);
    // delay so the spinner has chance to show
    setTimeout(() => {
      download().finally(() => setExtracting(false));
    }, 500);
  };

  return (
    <>
      {extracting && (
        <Alert variant="info">
          <Spinner animation="border" size="sm" />
          <span> Building log file</span>
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col xs="12" className="mt-2">
          <p>
            If you are having issues with the app and it the log file is
            requested please press the button below.
          </p>
          <p>
            This is will generate the log file and download it automatically,
            you will be able to locate this file in your Downloads folder
          </p>
        </Col>
        <Col xs="12" className="mt-2">
          <Button onClick={handleDownloadLog} disabled={extracting}>
            Download log
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Help;
