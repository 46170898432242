import axios from 'axios';

const SESSION_JWT_KEY = 'myJWT';

let token = localStorage.getItem(SESSION_JWT_KEY) || '';

const headers = token ? {
    Authorization: `Bearer ${token}`
} : {};

let ApiConnection = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    headers,
});

export const updateApiAuthorisation = (authToken: string) => {
    const headers = authToken ? {
        Authorization: `Bearer ${authToken}`
    } : {};

    ApiConnection = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL_API,
        headers,
    });

    localStorage.setItem(SESSION_JWT_KEY, authToken);

}

export const getApiConnection = () => {
    return ApiConnection;
}

export enum FetchState {
    Init,
    Request,
    Received,
    Failed,
    Cancelled,
}

export enum ChangesState {
    NoChanges,
    ChangesFound,
    ChangesProcessed,
}