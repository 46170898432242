import Dexie from "dexie";

export enum LogLevel {
  fatal = 100,
  error = 200,
  warn = 300,
  info = 400,
  debug = 500,
  trace = 600,
}

interface LogEntryContext {
  driver: string;
  system: string;
  [key: string]: any;
}

interface LogEntry {
  level: LogLevel;
  context: LogEntryContext;
  message: string;
  sequence: number;
  time: number;
}

type LogDexie = Dexie & {
  logs: Dexie.Table<LogEntry, number>;
};

const logDB = new Dexie("PCDS_Log") as LogDexie;

logDB.version(1).stores({
  logs: "++id, context, message, sequence, time, version",
});

logDB.version(2).stores({
  logs: "++id, level, context, message, sequence, time",
});

async function getDB() {
  if (!logDB.isOpen()) {
    // Try to re-open connection
    await logDB.open();
  }

  return logDB;
}

export const fetchLogs = async () => {
  const db = await getDB();

  return await db.logs.toArray();
};

export const getLogFile = async () => {
  const logs = await fetchLogs();

  return new Blob(
    logs.map((item) => JSON.stringify(item, null) + "\n"),
    { type: "text/plain", endings: "native" }
  );
};

export type LogFunction = (message: string, context?: Object) => void;

export type Logger = {
  error: LogFunction;
  warn: LogFunction;
  info: LogFunction;
  debug: LogFunction;
  setContext: (changes: Partial<GlobalLoggerContext>) => void;
  wipe: () => Promise<void>;
};

export type GlobalLoggerContext = {
  driver: string;
  system: string;
  version: string;
};
export class IndexLogger implements Logger {
  private globalContext: GlobalLoggerContext = {
    driver: "",
    system: "",
    version: "-",
  };
  private seq: number = 0;

  constructor(system: string) {
    this.setContext({ system });
    this.debug("Logger initialised");
  }

  error(message: string, context?: Object) {
    console.error(message, this.getContext(context));
    this.log(LogLevel.error, this.getContext(context), message);
  }
  warn(message: string, context?: Object) {
    console.warn(message, this.getContext(context));
    this.log(LogLevel.warn, this.getContext(context), message);
  }
  info(message: string, context?: Object) {
    console.info(message, this.getContext(context));
    this.log(LogLevel.info, this.getContext(context), message);
  }
  debug(message: string, context?: Object) {
    console.debug(message, this.getContext(context));
    this.log(LogLevel.debug, this.getContext(context), message);
  }

  setContext(changes: Partial<GlobalLoggerContext>) {
    this.globalContext = { ...this.globalContext, ...changes };
    this.debug("Logger context changed", { changes });
  }

  async wipe() {
    const db = await getDB();
    await db.logs.clear();
  }

  private getContext(context?: Object) {
    return { ...(context ?? {}), ...this.globalContext };
  }

  // context, message, sequence, time, version
  private log(level: LogLevel, context: LogEntryContext, message: string) {
    const sequence = ++this.seq;
    getDB().then((db) =>
      db
        .transaction("rw!", db.logs, () => {
          db.logs.add({
            level,
            context,
            message,
            sequence,
            time: Date.now(),
          });
        })
        .catch((e) => console.error("Failed to write to log", { e }))
    );
  }
}
