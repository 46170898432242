import { getApiConnection } from "./common";
import { AxiosResponse } from "axios";
import { ITrip, IParcel } from "./mapper/driver";

const COLLECTION_URI = 'courier';

export const fetchCourierTripDetails = (): Promise<AxiosResponse<ITrip[]>> => {
    const uri = `${COLLECTION_URI}/trip`;

    return getApiConnection().get(uri);
}

export const fetchCourierExpectedParcels = (): Promise<AxiosResponse<IParcel[]>> => {
    const uri = `${COLLECTION_URI}/parcel`;

    return getApiConnection().get(uri);
}