import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  RecordStore,
  instance,
  ServiceWorkerActions,
  ServiceWorkerSync,
} from "pcd_library";
import { authenticateServiceWorkerActions } from "./lib/http/user";
import Authentication from "./lib/authentication";
import { LoggerProvider, logger } from "./lib/logger";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://90d7ff38e800db358d63b6c353285309@o4507613881827328.ingest.de.sentry.io/4507613913415760",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.pcds\.co\.uk/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.npm_package_version || "0.2.9",
});

const myRecordStore = new RecordStore(logger);

const initUserAuth = Authentication.retrieveUserAuthentication();

const syncFn = (sync: string) => {
  logger.info("Sync function called", { sync });
  navigator.serviceWorker.ready.then(
    (swRegistration: ServiceWorkerRegistration) => {
      // @ts-ignore
      return swRegistration.sync.register(sync);
    }
  );

  // Only relevant for development
  if (process.env.NODE_ENV !== "production") {
    console.log("Development mock sw send", sync);
    const actions = new ServiceWorkerActions(
      process.env.REACT_APP_BASE_URL_API || ""
    );
    actions.ready.then(() => {
      authenticateServiceWorkerActions(actions)
        .then(() => {
          actions.log(`Running mock function for: ${sync}`);
          switch (sync) {
            case ServiceWorkerSync.SendScans:
              actions.sendAllPendingScans();
              break;
            case ServiceWorkerSync.SendDriverParcels:
              actions.sendAllPendingDriverTripParcels();
              break;
            case ServiceWorkerSync.SendCourierParcels:
              actions.sendAllPendingCourierTripParcels();
              break;
            case ServiceWorkerSync.SendDropParcels:
              actions.sendAllPendingDropParcels();
              break;
          }
        })
        .catch((e) => {
          logger.error("Unable to authenticate mock service worker actions", {
            error: e,
          });
        });
    });
  } else {
    logger.debug("Not in development");
  }
};

const debouncedSyncRequests: { [key: string]: number } = {};

const sendSyncRequest = (sync: string) => {
  console.log(`Debounce send request for ${sync}`);
  if (debouncedSyncRequests[sync]) {
    console.log(`Cleared the timeout for ${sync}`);
    clearTimeout(debouncedSyncRequests[sync]);
  }
  debouncedSyncRequests[sync] = window.setTimeout(() => syncFn(sync), 2000);
};

const container = document.getElementById("root");

if (!container) {
  console.error("Failed to find root");
} else {
  const root = createRoot(container);

  root.render(
    <LoggerProvider>
      <App
        appSettings={instance}
        appStore={myRecordStore}
        requestSync={sendSyncRequest}
        initialAuthentication={initUserAuth}
      />
    </LoggerProvider>
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => window.location.reload(),
});
