import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import { USER_TYPE, humanLabelFormatDriver, humanLabelFormatCourier } from 'pcd_library';

export type ManualProps = {
    onSubmit: (submission: string) => void,
    userType: USER_TYPE,
    pattern?: string,
    expected?: string,
    instruction?:string,
    placeholder?:string,
}

const DefaultInstruction = 'Please enter the full reference found at the bottom of the label including spaces';
const DefaultPlaceholder = 'Package label';

const Manual: React.FC<ManualProps> = (props) => {
    const [validated, setValidated] = useState(false);
    const [latestValue, setLatestValue] = useState<string>('');

    const expectedHumanFormat = props.expected || (props.userType === USER_TYPE.Driver ? humanLabelFormatDriver : humanLabelFormatCourier);
    const instruction = props.instruction || DefaultInstruction;
    const placeholder = props.placeholder || DefaultPlaceholder;

    const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidated(true);

        if (event.currentTarget.checkValidity() === false) {    
            return
        }
        
        props.onSubmit(latestValue);
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLatestValue(event.target.value)
    }

    return (
        <Form noValidate validated={validated} onSubmit={submitHandler}>
            <Row>
                <Col sm={12} md={6}>
                    <Form.Group controlId="manual_label_input">
                        <Form.Label>{instruction}</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder={placeholder}
                            defaultValue=""
                            pattern={props.pattern}
                            onChange={changeHandler}
                            isValid={false}
                        />
                        <Form.Control.Feedback>
                            Checking label
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Expected label format: {expectedHumanFormat}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                    <Button variant="primary" type="submit">Submit</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default Manual