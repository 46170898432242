import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { useLogger } from "../lib/logger";

export type UpdateProps = {
  automaticUpdateOn: boolean;
  toggleAutomaticUpdate: () => void;
  doUpdateNow: () => Promise<any>;
};

const Update: React.FC<UpdateProps> = ({
  automaticUpdateOn,
  toggleAutomaticUpdate,
  doUpdateNow,
}) => {
  const [checkNowDisabled, setCheckNowDisabled] = useState<boolean>(false);
  const { logger } = useLogger();

  useEffect(() => {
    logger.debug("Section: Entered Update");
    return () => logger.debug("Section: Exited Update");
  }, [logger]);

  const handleCheckNow = () => {
    setCheckNowDisabled(true);

    doUpdateNow().finally(() => setCheckNowDisabled(false));
  };

  return (
    <>
      {checkNowDisabled && (
        <Alert variant="warning">
          <Spinner animation="border" size="sm" />
          <span>Checking for updates</span>
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col xs="12">
          Automatic updates{" "}
          <input
            type="checkbox"
            checked={automaticUpdateOn}
            onChange={toggleAutomaticUpdate}
          />
        </Col>
        <Col xs="12" className="mt-2">
          <Button onClick={handleCheckNow} disabled={checkNowDisabled}>
            Check for updates now
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Update;
