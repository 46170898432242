// 1.1.1      249685  – the Campaign Number id in the existing warehouse database (long integer)
// 1.1.2      192         – the Drop Point id in the existing warehouse database (3 char string)
// 1.1.3      6JC         – the Poster Size id in the existing warehouse database (3 char string)
// 1.1.4      25           – the number of Posters in the parcel (long integer)
// 1.1.5      N             – Y/N depending if the Drop Point in 1.1.2 is defined as a courier delivery (1 char string)
// 1.1.6      26135    – unique header id for this Label in the existing warehouse database (long integer)
// 1.1.7      1              – sub-number for this label in the existing warehouse database (integer)

import LabelParseException from "./exceptions/LabelParseException"

// export const regexpLabel = /\d{5,}  ?[\d\w]{3}  ?[\d\w]{3}  ?\d{1,}  ?(?:Y|N)  ?\d{5,}  ?\d{1,}/i
export const regexpLabel = /\s*(?:y|n|Y|N)[\d\w]{3}[\d\w]{3}\s+\d{1,}\s+\d{1,}\s*/i
//export const regexpLabelCapture = /(\d{5,})  ?([\d\w]{3})  ?([\d\w]{3})  ?(\d{1,})  ?(Y|N)  ?(\d{5,})  ?(\d{1,})/i
export const regexpLabelCapture = /(Y|N)([\d\w]{3})([\d\w]{3})\s+(\d{1,})\s+(\d{1,})/i
export const humanLabelFormatCourier = 'Y123123 12345 1';
export const humanLabelFormatDriver = 'N123123 12345 1';

export type DropType = 'Y' | 'N';
export const ValidDropTypes = ['Y', 'N'];

/**
 * A container for the Parcel designation 
 */
export class Code {

    private _campaign: number;
    private _drop: string;
    private _size: string;
    private _count: number;
    private _type: DropType;
    private _headerId: number;
    private _sub: number;
    private _parentDrop: string;
    
    constructor(parentDrop: string, drop: string, type: DropType, headerId: number, sub: number) {
        this._campaign = 0;
        this._drop = drop;
        this._size = '';
        this._count = 0;
        this._type = type;
        this._headerId = headerId;
        this._sub = sub;
        this._parentDrop = parentDrop;
    }

    static parseLabel(label: string): Code {
        if (!regexpLabel.test(label)) {
            throw new LabelParseException('Label is not the correct format')
        }

        const matches = label.match(regexpLabelCapture);

        if (!matches) {
            throw new LabelParseException('Label could not be read correctly');
        }

        const matchValues = matches.slice(1);

        console.log(matchValues);

        const [rawDropType, drop, parentDrop, rawHeaderId, rawSub] = matchValues;

        if (ValidDropTypes.indexOf(rawDropType.toUpperCase()) === -1) {
            const highlight = this.highlightBrokenLabel(rawDropType, 0, matchValues);
            throw new LabelParseException('Unexpected drop type in label, ' + highlight);
        }

        const dropType: DropType = rawDropType.toUpperCase() as DropType;

        const headerId = this.parseExpectedInteger(rawHeaderId, 3, matchValues);
        const sub = this.parseExpectedInteger(rawSub, 4, matchValues);

        return new Code(parentDrop, drop, dropType, headerId, sub);
    }

    static parseExpectedInteger(rawValue: string, fieldIndex: number, matches: string[]): number {
        const value = parseInt(rawValue, 10);

        if (Number.isNaN(value)) {
            const highlight = this.highlightBrokenLabel(rawValue, fieldIndex, matches);
            throw new LabelParseException('Unable to read expected number from the label, ' + highlight);
        }

        return value;
    }

    static highlightBrokenLabel(rawValue: string, fieldIndex: number, matches: string[]) {
        matches.slice(0, fieldIndex).concat([`[${rawValue}]`]).concat(matches.slice(fieldIndex + 1)).join(' ');
    }

    get campaign(): number {
        return this._campaign
    }
    get drop(): string {
        return this._drop
    }
    get size(): string {
        return this._size
    }
    get count(): number {
        return this._count
    }
    get type(): DropType {
        return this._type
    }
    get headerId(): number {
        return this._headerId
    }
    get sub(): number {
        return this._sub
    }

    get parentDrop(): string {
        return this._parentDrop;
    }

    isChildDropoff(): boolean {
        return this._parentDrop !== this._drop;
    }
}

export default  {LabelParseException, regexpLabel, regexpLabelCapture, humanLabelFormatCourier, humanLabelFormatDeliveryVan: humanLabelFormatDriver, ValidDropTypes, Code};