import React from "react";
import Alert from "react-bootstrap/Alert";
import { IScan } from "pcd_library";

export type ConnectionAlertProps = {
  isConnected: boolean;
  isServerVisible: boolean;
  pendingScans: IScan[];
};

const ConnectionAlert: React.FC<ConnectionAlertProps> = (props) => {
  if (props.isConnected && props.isServerVisible) {
    return <></>;
  }

  if (props.isConnected && !props.isServerVisible) {
    return (
      <Alert className="connection-alert" variant="info">
        Server is unreachable, parcels scanned will be queued until you are back
        online with a server connection.
        {props.pendingScans.length > 0 && (
          <span>Pending: {props.pendingScans.length}</span>
        )}
      </Alert>
    );
  }

  return (
    <Alert className="connection-alert" variant="warning">
      You are currently offline, parcels scanned will be queued until you are
      back online.
      {props.pendingScans.length > 0 && (
        <span>Pending: {props.pendingScans.length}</span>
      )}
    </Alert>
  );
};

export default ConnectionAlert;
