import React from 'react';
import { FetchState } from '../../lib/http/common';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type DataFetchProps = {
    parcelFetch: FetchState;
    tripFetch: FetchState;
}

const DataFetch: React.FC<DataFetchProps> = (props) => {
    const {parcelFetch, tripFetch} = props;

    return (<Alert variant="info">
            <p>Fetching your trip and parcel information:</p>
            <Row>
                <Col xs="2">
                    {tripFetch === FetchState.Received ?
                        <FontAwesomeIcon className="text-success" icon="check-square" size="sm"/> :
                        <Spinner size="sm" animation="border" />}
                </Col>
                <Col xs="8">Trip details</Col>
            </Row>
            <Row>
                <Col xs="2">
                    {parcelFetch === FetchState.Received ?
                         <FontAwesomeIcon className="text-success" icon="check-square" size="sm"/> :
                         <Spinner size="sm" animation="border" />}
                </Col>
                <Col xs="8">Parcel details</Col>
            </Row>
        </Alert>);
}

export default DataFetch;