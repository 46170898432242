import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faHistory,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare as farSquare } from "@fortawesome/free-regular-svg-icons";

import Login from "./sections/Login";
import Logout from "./sections/Logout";
import {
  Settings,
  RecordStore,
  IOfflineScan,
  USER_TYPE,
  IOfflineTripParcel,
  IOfflineTripDetails,
  ScanState,
  Code,
  IScan,
  ServiceWorkerSync,
  IOfflineDropPoint,
  DropPoint,
  IDropPoint,
  IDropStatus,
  ChangeSubscriber,
} from "pcd_library";

import Can, { AbilityContext } from "./config/Can";
import ability, { updateUserAbilities } from "./config/ability";
import ProtectedRoute from "./components/ProtectedRoute";
import Authentication from "./lib/authentication";
import DefaultActionRoute from "./components/DefaultActionRoute";
import Pending from "./sections/Pending";
import {
  authenticateUser,
  logoutUser,
  authenticateServiceWorker,
  checkServerUpdates,
  acknowledgeServerUpdates,
  authenticateUserAs,
} from "./lib/http/user";
import { AuthenticationDetails } from "./lib/interfaces/IUserAuthentication";
import Removal from "./sections/Removal";
import {
  fetchDriverExpectedParcels,
  fetchDriverTripDetails,
  fetchDriverDropPoints,
  updateDriverLeaveParcels,
} from "./lib/http/driver";
import { FetchState, ChangesState } from "./lib/http/common";
import Loadup from "./sections/Loadup";
import DriverMapper from "./lib/http/mapper/driver";
import Collect from "./sections/Collect";
import Pickup from "./sections/Pickup";
import {
  fetchCourierTripDetails,
  fetchCourierExpectedParcels,
} from "./lib/http/courier";
import TripSummary from "./sections/TripSummary";
import UpdateAlert from "./components/UpdateAlert";
import Deliver from "./sections/Deliver";
import DeliverParcel from "./sections/DeliverParcel";

import LeaveParcels from "./sections/LeaveParcels";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Update from "./sections/Update";
import UIfx from "./lib/uifx";
import { createStatMap, dropIsComplete } from "./lib/tripdetail";
import { TripParcelStatus } from "./lib/types/loadup";
import { useLogger } from "./lib/logger";
import Help from "./sections/Help";
import ConnectionAlert from "./components/ConnectionAlert";

const VERSION = "0.2.9";

const goodScan = require("./sounds/scan.mp3");
const errorScan = require("./sounds/error.mp3");
const specialErrorScan = require("./sounds/special-error.mp3");

const goodScanFx = new UIfx(goodScan, {
  vibrate: [100],
});
const errorScanFx = new UIfx(errorScan, {
  throttleMs: 250,
  vibrate: [350, 10, 350],
});

const specialErrorScanFx = new UIfx(specialErrorScan, {
  throttleMs: 250,
  vibrate: [150, 10, 150, 10, 150],
});

export type AppProps = {
  appSettings: Settings;
  appStore: RecordStore;
  requestSync: (sync: string) => void;
  initialAuthentication: Authentication;
};

library.add(faCheckSquare, farSquare, faHistory, faTimesCircle);

const UPDATE_CYCLE = 60000;

const App: React.FC<AppProps> = (props) => {
  const { logger } = useLogger();

  const { appSettings, appStore, requestSync } = props;

  const [appOnline, setAppOnline] = useState(true);
  const [isServerVisible, setIsServerVisible] = useState(true);
  const [appHidden, setAppHidden] = useState(false);
  const [pendingScans, setPendingScans] = useState<IOfflineScan[]>([]);

  const [autoUpdate, setAutoUpdate] = useState<boolean>(appSettings.AutoUpdate);

  const [userAuthentication, setUserAuthentication] = useState<Authentication>(
    props.initialAuthentication
  );

  const [tripParcels, setTripParcels] = useState<IOfflineTripParcel[]>([]);
  const [tripDetails, setTripDetails] = useState<IOfflineTripDetails[]>([]);
  const [allTripParcelsScanned, setAllTripParcelsScanned] =
    useState<boolean>(false);

  const [dropPoints, setDropPoints] = useState<IOfflineDropPoint[]>([]);
  const [dropParcels, setDropParcels] = useState<IOfflineTripParcel[]>([]);
  const [gatherDropParcels, setGatherDropParcels] = useState<FetchState>(
    FetchState.Init
  );

  const [fetchTripState, setFetchTripState] = useState<FetchState>(
    FetchState.Init
  );
  const [fetchTripParcelsState, setFetchTripParcelsState] =
    useState<FetchState>(FetchState.Init);
  const [fetchDropPointsState, setFetchDropPointsState] = useState<FetchState>(
    FetchState.Init
  );

  const [checkForUpdatesState, setCheckForUpdatesState] = useState<FetchState>(
    FetchState.Init
  );
  // Change to something more granular
  const [checkForUpdatesResult, setCheckForUpdatesResult] =
    useState<ChangesState>(ChangesState.NoChanges);
  const [updateTimeout, setUpdateTimeout] = useState<number>(0);

  const [tripStatus, setTripStatus] = useState<IDropStatus[]>([]);
  const [tripLoadupStatus, setTripLoadupStatus] = useState<TripParcelStatus>(
    {}
  );

  const [initialRender, setInitialRender] = useState<boolean>(true);

  const [numberOfPendingItems, setNumberOfPendingItems] = useState<number>(0);

  const [activeDelivery, setActiveDelivery] = useState<string>(
    appSettings.ActiveDelivery
  );

  const [isPendingDelivery, setIsPendingDelivery] = useState(false);

  const checkPendingTimout = useRef(0);

  useEffect(() => {
    logger.setContext({
      driver: userAuthentication.isLoggedIn()
        ? userAuthentication.displayName
        : "",
      version: VERSION,
    });
  }, [logger, userAuthentication]);

  useEffect(() => {
    let currentCount = pendingScans.length;

    if (userAuthentication.role !== USER_TYPE.Warehouse) {
      tripParcels.forEach((parcel) => {
        if (parcel.state === ScanState.Pending) {
          ++currentCount;
        }
      });

      if (allTripParcelsScanned) {
        dropParcels.forEach((parcel) => {
          if (parcel.state === ScanState.Pending) {
            ++currentCount;
          }
        });
      }
    }

    setNumberOfPendingItems(currentCount);
  }, [
    pendingScans,
    tripParcels,
    dropParcels,
    userAuthentication,
    allTripParcelsScanned,
  ]);

  useEffect(() => {
    clearTimeout(checkPendingTimout.current);
    if (numberOfPendingItems > 3) {
      checkPendingTimout.current = window.setTimeout(() => {
        logger.info(`Running pending clock action`);
        try {
          logger.debug("App: Request send scans");
          requestSync(ServiceWorkerSync.SendScans);
          logger.debug("App: Request send driver parcels");
          requestSync(ServiceWorkerSync.SendDriverParcels);
          logger.debug("App: Request send courier parcels");
          requestSync(ServiceWorkerSync.SendCourierParcels);
          logger.debug("App: Request send driver trip");
          requestSync(ServiceWorkerSync.SendDriverTrip);
          logger.debug("App: Request send drop parcels");
          requestSync(ServiceWorkerSync.SendDropParcels);
          logger.debug("App: Request send complete");
        } catch (error) {
          logger.error("App: Request send error occurred", { error });
        }
      }, 300000); // Check every
    }
  }, [numberOfPendingItems, logger, requestSync]);

  useEffect(() => {
    const statMap = createStatMap(tripStatus);

    const isIncompleteDrop = tripDetails.some(
      (record) => !dropIsComplete(statMap, record.dropid)
    );

    setIsPendingDelivery(isIncompleteDrop);
  }, [tripDetails, tripStatus]);

  const runUpdateDrops = () => {
    appStore.updateAllDropCompletionStatus().then((status) => {
      setTripStatus(status);
    });
  };

  const performUpdates = () => {
    let result;

    switch (userAuthentication.role) {
      case USER_TYPE.Driver:
        // load up trip details for driver
        result = Promise.all([
          fetchDriverTripDetails().then((response) => {
            const trips = Array.prototype.concat.apply(
              [],
              response.data.map(DriverMapper.mapTripToDexie)
            );
            logger.info("Trip details updated", { trips });
            return appStore.storeTripDetailsUpdate(trips);
          }),
          fetchDriverExpectedParcels().then((response) => {
            const parcels = Array.prototype.concat.apply(
              [],
              response.data.map(DriverMapper.mapParcelToDexie)
            );
            logger.info("Driver parcels updated", { parcels });
            return appStore.storeTripParcelsUpdate(parcels);
          }),
          fetchDriverDropPoints().then((response) => {
            const points = response.data.map(DriverMapper.mapDropToDexie);
            logger.info("Driver points updated", { points });
            return appStore.storeDropPointsUpdate(points);
          }),
        ]);
        break;
      case USER_TYPE.Courier:
        result = Promise.all([
          fetchCourierTripDetails().then((response) => {
            const trips = Array.prototype.concat.apply(
              [],
              response.data.map(DriverMapper.mapTripToDexie)
            );
            return appStore.storeTripDetailsUpdate(trips);
          }),

          fetchCourierExpectedParcels().then((response) => {
            const parcels = Array.prototype.concat.apply(
              [],
              response.data.map(DriverMapper.mapParcelToDexie)
            );
            return appStore.storeTripParcelsUpdate(parcels);
          }),
        ]);
        break;
    }

    return result;
  };

  const handleServerUpdates = (hasUpdates: boolean) => {
    setCheckForUpdatesState(FetchState.Received);

    logger.info(
      `Checked for updates and server said hasupdates = ${hasUpdates}`
    );

    return new Promise((resolve) => {
      let result;
      logger.info("Starting the update, checking", {
        role: userAuthentication.role,
        accepted: [USER_TYPE.Driver, USER_TYPE.Courier],
        hasUpdates,
      });
      if (
        hasUpdates &&
        [USER_TYPE.Driver, USER_TYPE.Courier].indexOf(
          userAuthentication.role
        ) !== -1
      ) {
        logger.info("update proceeding");
        setCheckForUpdatesResult(ChangesState.ChangesFound);

        result = performUpdates();

        if (result) {
          result.finally(() => {
            setCheckForUpdatesResult(ChangesState.ChangesProcessed);
            acknowledgeServerUpdates();
            resolve(hasUpdates);
          });
        }
      } else {
        // Don't show updates for other roles or no updates
        setCheckForUpdatesResult(ChangesState.NoChanges);
        resolve(false);
      }
    });
  };

  const checkForUpdates = () => {
    setCheckForUpdatesResult(ChangesState.NoChanges);
    setCheckForUpdatesState(FetchState.Request);
    return checkServerUpdates().then(handleServerUpdates);
  };

  const startUpdateCheckCycle = () => {
    clearTimeout(updateTimeout);

    if (userAuthentication.role === USER_TYPE.Warehouse) {
      return;
    }

    checkForUpdates().finally(() => {
      if (
        userAuthentication.isLoggedIn() &&
        (appSettings.AutoUpdate || autoUpdate)
      ) {
        scheduleUpdateCheck();
      }
    });
  };

  const scheduleUpdateCheck = () => {
    setUpdateTimeout(
      window.setTimeout(
        startUpdateCheckCycle,
        UPDATE_CYCLE + Math.floor(Math.random() * 1000)
      )
    );
  };

  const stopUpdateCheckCycle = () => {
    clearTimeout(updateTimeout);
  };

  if (initialRender && props.initialAuthentication.isLoggedIn()) {
    // do a check / restore
    setInitialRender(false);

    const { role } = props.initialAuthentication;

    switch (role) {
      case USER_TYPE.Courier:
      case USER_TYPE.Driver:
        setFetchTripParcelsState(FetchState.Received);
        setFetchTripState(FetchState.Received);
        setFetchDropPointsState(FetchState.Received);

        if (appSettings.DataFetchComplete < appSettings.DataFetchStarted) {
          // failed data fetch, force an update
          const updatePromise = handleServerUpdates(true);
          if (updatePromise) {
            updatePromise.finally(() => startUpdateCheckCycle());
          }
        } else {
          startUpdateCheckCycle();
        }
        break;
    }
  }

  const doUserLogin = (userId: string) => {
    return new Promise<string>((resolve, reject) => {
      logger.info("Start of login");
      clearAll();
      logger.info("Cleardown done");
      appStore.clearDown().finally(() =>
        authenticateUser(userId)
          .then((response) => {
            logger.wipe().finally(() => {
              logger.info("Appstore cleardown complete, user authenticated", {
                userId,
              });
              // TODO store in session storage??

              const authDetails: AuthenticationDetails = {
                ...response.data.token.data,
                expire: parseInt(response.data.token.exp, 10),
              };

              const userAuth = new Authentication(authDetails);

              userAuth.storeUserAuthentication();
              setUserAuthentication(userAuth);

              // Load into the service worker
              authenticateServiceWorker().catch(() => {
                logger.error(
                  "Unable to authenticate service worker, data will not be sent to the server correctly"
                );
              });

              switch (userAuth.role) {
                case USER_TYPE.Driver:
                  appSettings.DataFetchStarted = new Date().getTime();
                  setFetchTripParcelsState(FetchState.Request);
                  setFetchTripState(FetchState.Request);
                  // load up trip details for driver
                  fetchDriverTripDetails()
                    .then((response) => {
                      const trips = response.data.map(
                        DriverMapper.mapTripToDexie
                      );

                      appStore.storeTripDetails(trips).then(() => {
                        setFetchTripState(FetchState.Received);
                      });
                    })
                    .catch(() => {
                      setFetchTripState(FetchState.Failed);
                    });
                  fetchDriverExpectedParcels()
                    .then((response) => {
                      const parcels = response.data.map(
                        DriverMapper.mapParcelToDexie
                      );

                      appStore
                        .storeTripParcels(
                          Array.prototype.concat.apply([], parcels)
                        )
                        .then(() => {
                          setFetchTripParcelsState(FetchState.Received);
                        });
                    })
                    .catch(() => {
                      setFetchTripParcelsState(FetchState.Failed);
                    });
                  fetchDriverDropPoints()
                    .then((response) => {
                      const points = response.data.map(
                        DriverMapper.mapDropToDexie
                      );

                      appStore.storeDropPoints(points).then(() => {
                        setFetchDropPointsState(FetchState.Received);
                      });
                    })
                    .catch(() => {
                      // TODO Mark off failure to load
                      setFetchDropPointsState(FetchState.Failed);
                    });
                  break;
                case USER_TYPE.Courier:
                  appSettings.DataFetchStarted = new Date().getTime();
                  setFetchTripParcelsState(FetchState.Request);
                  setFetchTripState(FetchState.Request);

                  fetchCourierTripDetails().then((response) => {
                    const trips = response.data.map(
                      DriverMapper.mapTripToDexie
                    );

                    appStore.storeTripDetails(trips).then(() => {
                      setFetchTripState(FetchState.Received);
                    });
                  });

                  fetchCourierExpectedParcels().then((response) => {
                    const parcels = response.data.map(
                      DriverMapper.mapParcelToDexie
                    );

                    appStore
                      .storeTripParcels(
                        Array.prototype.concat.apply([], parcels)
                      )
                      .then(() => {
                        setFetchTripParcelsState(FetchState.Received);
                      });
                  });
                  break;
              }

              // jump first cycle
              if (autoUpdate) {
                scheduleUpdateCheck();
              }

              resolve(response.statusText);
            });
          })
          .catch((e) => {
            reject(e);
          })
      );
    });
  };

  const doUserLoginAs = (userId: string) => {
    logger.info("Login as: started");
    return new Promise<string>((resolve, reject) => {
      logger.info("Login as: clear down start");
      clearAll();
      logger.info("Login as: clear all done");
      appStore.clearDown().finally(() =>
        authenticateUserAs(userId)
          .then((response) => {
            logger.info("Login as: Authenticated and store cleared");
            // TODO store in session storage??

            const authDetails: AuthenticationDetails = {
              ...response.data.token.data,
              expire: parseInt(response.data.token.exp, 10),
            };

            const userAuth = new Authentication(authDetails);

            userAuth.storeUserAuthentication();
            setUserAuthentication(userAuth);

            // Load into the service worker
            authenticateServiceWorker().catch(() => {
              logger.error(
                "Unable to authenticate service worker, data will not be sent to the server correctly"
              );
            });

            switch (userAuth.role) {
              case USER_TYPE.Driver:
                appSettings.DataFetchStarted = new Date().getTime();
                setFetchTripParcelsState(FetchState.Request);
                setFetchTripState(FetchState.Request);
                // load up trip details for driver
                fetchDriverTripDetails()
                  .then((response) => {
                    const trips = response.data.map(
                      DriverMapper.mapTripToDexie
                    );

                    appStore.storeTripDetails(trips).then(() => {
                      setFetchTripState(FetchState.Received);
                    });
                  })
                  .catch(() => {
                    setFetchTripState(FetchState.Failed);
                  });
                fetchDriverExpectedParcels()
                  .then((response) => {
                    const parcels = response.data.map(
                      DriverMapper.mapParcelToDexie
                    );

                    appStore
                      .storeTripParcels(
                        Array.prototype.concat.apply([], parcels)
                      )
                      .then(() => {
                        setFetchTripParcelsState(FetchState.Received);
                      });
                  })
                  .catch(() => {
                    setFetchTripParcelsState(FetchState.Failed);
                  });
                fetchDriverDropPoints()
                  .then((response) => {
                    const points = response.data.map(
                      DriverMapper.mapDropToDexie
                    );

                    appStore.storeDropPoints(points).then(() => {
                      setFetchDropPointsState(FetchState.Received);
                    });
                  })
                  .catch(() => {
                    // TODO Mark off failure to load
                    setFetchDropPointsState(FetchState.Failed);
                  });
                break;
              case USER_TYPE.Courier:
                appSettings.DataFetchStarted = new Date().getTime();
                setFetchTripParcelsState(FetchState.Request);
                setFetchTripState(FetchState.Request);

                fetchCourierTripDetails().then((response) => {
                  const trips = response.data.map(DriverMapper.mapTripToDexie);

                  appStore.storeTripDetails(trips).then(() => {
                    setFetchTripState(FetchState.Received);
                  });
                });

                fetchCourierExpectedParcels().then((response) => {
                  const parcels = response.data.map(
                    DriverMapper.mapParcelToDexie
                  );

                  appStore
                    .storeTripParcels(Array.prototype.concat.apply([], parcels))
                    .then(() => {
                      setFetchTripParcelsState(FetchState.Received);
                    });
                });
                break;
            }

            // jump first cycle
            if (autoUpdate) {
              scheduleUpdateCheck();
            }

            resolve(response.statusText);
          })
          .catch((e) => {
            reject(e);
          })
      );
    });
  };

  useEffect(() => {
    if (userAuthentication.role === USER_TYPE.Courier) {
      if (
        fetchTripState === FetchState.Received &&
        fetchTripParcelsState === FetchState.Received
      ) {
        appSettings.DataFetchComplete = new Date().getTime();
      }
    } else if (userAuthentication.role === USER_TYPE.Driver) {
      if (
        fetchTripState === FetchState.Received &&
        fetchTripParcelsState === FetchState.Received &&
        fetchDropPointsState === FetchState.Received
      ) {
        appSettings.DataFetchComplete = new Date().getTime();
      }
    }
  }, [
    fetchTripState,
    fetchTripParcelsState,
    fetchDropPointsState,
    userAuthentication,
    appSettings,
  ]);

  const doLogoutUser = () => {
    clearTimeout(updateTimeout);
    appStore.clearDown();
    logoutUser();
    Authentication.clearUserAuthentication();
    setUserAuthentication(new Authentication());
  };

  const handleConnectionChange = () => {
    const currentCondition = !!navigator.onLine;
    logger.debug(`Connection status changed to ${currentCondition}`, {
      online: navigator.onLine,
      // @ts-ignore Future proofing
      network: navigator.connection ? navigator.connection : "unsupported",
    });
    setAppOnline(currentCondition);
    checkServerVisibility();
  };

  const checkServerVisibility = () => {
    if (!!navigator.onLine) {
      logger.debug("App online, checking server visibility");
      fetch("https://app.pcds.co.uk/", {
        method: "HEAD",
      })
        .then(() => {
          setIsServerVisible(true);
          logger.debug("server reachable");
        })
        .catch(() => {
          setIsServerVisible(false);
          logger.debug("server not reachable");
        });
    } else {
      logger.debug("App not online, not checking for server");
    }
  };

  const loadupParcelScanned = useCallback(
    (parcel: IOfflineTripParcel) => {
      logger.info("Loadup parcel scanned", { parcel });
      if (!parcel.scannedAt && !parcel.state) {
        appStore
          .scannedTripParcel(parcel)
          .finally(() => requestSync(ServiceWorkerSync.SendDriverParcels));
      }
    },
    [appStore, logger, requestSync]
  );

  const pickupParcelScanned = useCallback(
    (parcel: IOfflineTripParcel) => {
      if (!parcel.scannedAt && !parcel.state) {
        appStore
          .scannedTripParcel(parcel)
          .finally(() => requestSync(ServiceWorkerSync.SendCourierParcels));
      }
    },
    [appStore, requestSync]
  );

  const handleLabelSent = (success: boolean, code: Code | null) => {
    if (!code) {
      logger.error("Request to store parcel scan but no parcel read");
      return;
    }

    const state = success ? ScanState.Sent : ScanState.Failed;
    appStore.storeScan(code, state);
  };

  const handleQueueCurrentRequestOffline = (code: Code | null) => {
    if (!code) {
      logger.error("Request to store parcel scan offline but no parcel read");
      return;
    }

    const scan: IScan = {
      headerId: code.headerId,
      sub: code.sub,
      drop: code.drop,
      type: code.type,
    };

    props.appStore.storeScan(scan, ScanState.Pending);

    requestSync(ServiceWorkerSync.SendScans);
  };

  const handleDropParcelScanned = useCallback(
    (
      dropParcel: Code,
      isUnknown: boolean = false,
      dropPoint: string = "",
      includeAll: boolean = false
    ) => {
      const found = tripParcels.find(
        (fparcel) =>
          fparcel.qrcode === dropParcel.headerId &&
          fparcel.sub === dropParcel.sub
      );

      let workDone = Promise.resolve(0);

      if (!found || !found.id) {
        return;
      }

      if (isUnknown && !includeAll) {
        workDone = appStore.reassignDropParcel(found.id, dropPoint);
      } else if (isUnknown && includeAll) {
        workDone = appStore.reassignDropParcels(found.qrcode, dropPoint);
      }

      workDone
        .then(() => {
          return appStore.getDropParcels().then((dropParcels) => {
            const found = dropParcels.find(
              (fparcel) =>
                fparcel.qrcode === dropParcel.headerId &&
                fparcel.sub === dropParcel.sub
            );
            if (!found || !found.id) {
              logger.error("Failed to find parcel in drop list");
              return 0;
            }
            appStore.getDropParent(found.dropPoint).then((parent) => {
              if (parent) {
                appStore.markDropStatus(parent.drop);
              }
            });

            return appStore.scannedDropParcel(found.id);
          });
        })
        .then(() => requestSync(ServiceWorkerSync.SendDropParcels));
    },
    [appStore, logger, requestSync, tripParcels]
  );

  const updateActiveDelivery = (dropid: string) => {
    appSettings.ActiveDelivery = dropid;
    setActiveDelivery(dropid);
  };

  const loadDropDetails = (droppoint: string) => {
    setGatherDropParcels(FetchState.Request);
    let workBeingDone = Promise.resolve(0);

    return workBeingDone
      .then(() => {
        return appStore.getDropPointsByParent(droppoint).then((drops) => {
          const scans: string[] = [];
          drops.forEach((drop) => {
            if (!drop.scannedAt) {
              // has not been scanned so parcels will need pulled over to offline
              scans.push(drop.drop);
            }
          });

          return appStore
            .createOfflineDropParcelsFor(scans)
            .then(() => {
              scans.length > 0 && appStore.scannedParentDropPoint(droppoint);
            })
            .then(() => drops);
        });
      })
      .finally(() => {
        setGatherDropParcels(FetchState.Received);
      });
  };

  const handleDropScanned = (
    droppoint: DropPoint,
    isUnknown: boolean = false
  ) => {
    let workBeingDone = Promise.resolve(0);

    if (isUnknown) {
      const bareDetails: IDropPoint = {
        drop: "0" + droppoint.drop,
        postcode: droppoint.postcode,
        trip: -1,
        name: "",
        parent: "0" + droppoint.drop,
      };
      workBeingDone = appStore.storeDropPoints([bareDetails]);
    }

    return workBeingDone;
  };

  const handleDoUpdateNow = () => {
    stopUpdateCheckCycle();
    return checkForUpdates().finally(() => {
      if (autoUpdate) {
        scheduleUpdateCheck();
      }
    });
  };

  const handleToggleUpdate = () => {
    const newValue = !autoUpdate;

    setAutoUpdate(newValue);
    appSettings.AutoUpdate = newValue;

    if (!newValue) {
      stopUpdateCheckCycle();
    } else {
      scheduleUpdateCheck();
    }
  };

  function clearAll() {
    setPendingScans([]);
    setTripParcels([]);
    setTripDetails([]);
    setDropPoints([]);
    setTripStatus([]);
    setDropParcels([]);
  }

  const populateDataFromStore = useCallback(() => {
    const updates: Promise<any>[] = [];

    updates.push(
      appStore.getPendingScans().then((scans) => setPendingScans(scans))
    );
    updates.push(
      appStore.getTripDetails().then((details) => setTripDetails(details))
    );
    updates.push(
      appStore.getTripParcels().then((parcels) => setTripParcels(parcels))
    );
    updates.push(
      appStore.getDropParcels().then((parcels) => setDropParcels(parcels))
    );
    updates.push(
      appStore
        .getDropPoints()
        .then((drops) => {
          setDropPoints(drops);
          return drops;
        })
        .then((drops) => {
          const dropParents: { [key: string]: number } = {};

          drops.forEach((drop) => (dropParents[drop.parent] = 1));

          appStore
            .getCompletionStatus(Object.keys(dropParents))
            .then((status) => setTripStatus(status));
        })
    );

    Promise.all(updates).finally(() => {
      logger.info("Ran table pull down");
    });
  }, [appStore, logger]);

  const handleDatabaseChange = useCallback<ChangeSubscriber>(
    (changes, partial) => {
      logger.info("Changes detected", { partial, changes });

      const tables = changes.reduce(
        (carry, val) => ({ ...carry, [val.table]: 1 }),
        {}
      );

      const updates: Promise<any>[] = [];

      Object.keys(tables).forEach((table) => {
        switch (table) {
          case "offlineScans":
            logger.info(`Changes to ${table}`);
            updates.push(
              appStore.getPendingScans().then((scans) => setPendingScans(scans))
            );
            break;
          case "offlineTrips":
            logger.info(`Changes to ${table}`);
            updates.push(
              appStore
                .getTripDetails()
                .then((details) => setTripDetails(details))
            );
            break;
          case "offlineParcels":
            logger.info(`Changes to ${table}`);
            updates.push(
              appStore
                .getTripParcels()
                .then((parcels) => setTripParcels(parcels))
            );
            break;
          case "offlineDropParcels":
            logger.info(`Changes to ${table}`);
            updates.push(
              appStore
                .getDropParcels()
                .then((parcels) => setDropParcels(parcels))
            );
            break;
          case "offlineDropsStatus": //Drops and status are linked
          case "offlineDrops":
            logger.info(`Changes to ${table}`);
            updates.push(
              appStore
                .getDropPoints()
                .then((drops) => {
                  setDropPoints(drops);
                  return drops;
                })
                .then((drops) => {
                  const dropParents: { [key: string]: number } = {};

                  drops.forEach((drop) => (dropParents[drop.parent] = 1));

                  appStore
                    .getCompletionStatus(Object.keys(dropParents))
                    .then((status) => setTripStatus(status));
                })
            );
            break;
        }
      });

      Promise.all(updates).finally(() => logger.info("Ran table responses"));
    },
    [appStore, logger]
  );

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    populateDataFromStore();
    const unsubscribe = appStore.onChanges(handleDatabaseChange);

    const visibilityChanged = () => {
      setAppHidden(document.hidden);
    };
    window.addEventListener("visibilitychange", visibilityChanged);

    const interval = setInterval(() => checkServerVisibility(), 5 * 60 * 1000);

    // @ts-ignore
    if (navigator.connection) {
      // @ts-ignore
      navigator.connection.onchange = checkServerVisibility;
    }

    return () => {
      window.removeEventListener("visibilitychange", visibilityChanged);
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
      unsubscribe();
      clearInterval(interval);
    };
  }, [appStore, handleDatabaseChange, populateDataFromStore]);

  useEffect(() => {
    updateUserAbilities(userAuthentication);
  }, [userAuthentication]);

  useEffect(() => {
    let result = false;

    if (tripParcels.length === 0) {
      result = true;
    } else {
      result = !tripParcels.some((parcel) => !parcel.scannedAt);
    }

    setAllTripParcelsScanned(result);

    appStore.getDropPoints().then((drops) => {
      const droppointStatus: TripParcelStatus = {};

      const dropsMap: { [key: string]: IOfflineDropPoint } = drops.reduce(
        (accum, drop) => {
          return { ...accum, [drop.drop]: drop };
        },
        {}
      );

      tripParcels.forEach((parcel) => {
        if (!droppointStatus[parcel.dropPoint]) {
          const drop = dropsMap[parcel.dropPoint];

          if (drop) {
            droppointStatus[parcel.dropPoint] = {
              drop,
              totalParcels: 0,
              totalScanned: 0,
            };
          }
        }
        if (!droppointStatus[parcel.dropPoint]) {
          logger.warn("Something went wrong mapping");
        } else {
          droppointStatus[parcel.dropPoint].totalParcels += 1;
          droppointStatus[parcel.dropPoint].totalScanned += parcel.scannedAt
            ? 1
            : 0;
        }

        const topStatus: TripParcelStatus = Object.values(
          droppointStatus
        ).reduce((accum, status) => {
          let result = { ...accum };

          if (!accum[status.drop.parent]) {
            result[status.drop.parent] = {
              drop: dropsMap[status.drop.parent],
              totalParcels: 0,
              totalScanned: 0,
            };
          }

          result[status.drop.parent].totalParcels += status.totalParcels;
          result[status.drop.parent].totalScanned += status.totalScanned;

          return result;
        }, {} as TripParcelStatus);

        setTripLoadupStatus(topStatus);
      });
    });
  }, [tripParcels, appStore, logger]);

  const doLeaveParcels = (
    parcels: IOfflineTripParcel[],
    reason: string
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      updateDriverLeaveParcels(parcels, reason)
        .then(() => {
          const ids: number[] = [];

          parcels.forEach((parcel) => {
            if (parcel.id) {
              ids.push(parcel.id);
            }
          });

          appStore
            .removeTripParcels(ids)
            .then(() => resolve(true))
            .catch(() => reject(false));
        })
        .catch(() => reject(false));
    });
  };

  const appTitle = useMemo(() => {
    return userAuthentication.isLoggedIn()
      ? userAuthentication.displayName
      : "";
  }, [userAuthentication]);

  if (appHidden) {
    if (!document.hidden) {
      setAppHidden(false);
    }
    return (
      <>
        <Navbar bg="primary" expand="lg" variant="dark" collapseOnSelect>
          <Navbar.Brand>{appTitle}</Navbar.Brand>
        </Navbar>
        <Container fluid className="mt-2">
          <Alert variant="info">
            Entered power save, click Resume to continue scanning
          </Alert>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button variant="success" onClick={() => setAppHidden(false)}>
                Resume
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <AbilityContext.Provider value={ability}>
      <Router>
        <Navbar bg="primary" expand="lg" variant="dark" collapseOnSelect>
          <Link to="/" className="navbar-brand">
            {appTitle + " "}
            <Navbar.Text className="small" style={{ fontSize: "50%" }}>
              v{VERSION}
            </Navbar.Text>
          </Link>
          <Navbar.Toggle aria-controls="toplevel-navbar-nav" />
          <Navbar.Collapse id="toplevel-navbar-nav">
            <Can I="load" an="Item">
              <Nav>
                <Nav.Link eventKey="collect" as={NavLink} to="/collect">
                  Scan items
                </Nav.Link>
              </Nav>
            </Can>
            <Can I="checkoff" an="Item">
              {userAuthentication.role === USER_TYPE.Driver && (
                <Nav>
                  <Nav.Link eventKey="loadup" as={NavLink} to="/loadup">
                    Scan items
                  </Nav.Link>
                </Nav>
              )}
              {userAuthentication.role === USER_TYPE.Courier && (
                <Nav>
                  <Nav.Link eventKey="courier" as={NavLink} to="/courier">
                    Scan items
                  </Nav.Link>
                </Nav>
              )}
            </Can>
            <Can I="view" a="Summary">
              <Nav>
                <Nav.Link
                  eventKey="trip-summary"
                  as={NavLink}
                  className="nav-link"
                  to="/trip-summary"
                >
                  Trip Summary
                </Nav.Link>
              </Nav>
            </Can>
            <Can I="deliver" an="Item">
              <Nav>
                <Nav.Link
                  eventKey="deliver"
                  as={NavLink}
                  className="nav-link"
                  to="/deliver"
                >
                  Deliver
                </Nav.Link>
              </Nav>
            </Can>
            <Can I="unload" an="Item">
              <Nav>
                <Nav.Link
                  eventKey="remove"
                  as={NavLink}
                  className="nav-link"
                  to="/remove"
                >
                  Remove items
                </Nav.Link>
              </Nav>
            </Can>
            <Can I="leave" an="Item">
              <Nav>
                <Nav.Link
                  eventKey="leave"
                  as={NavLink}
                  className="nav-link"
                  to="/leave"
                >
                  Leave items
                </Nav.Link>
              </Nav>
            </Can>
            <Can I="manage" an="Update">
              <Nav>
                <Nav.Link
                  eventKey="update-settings"
                  as={NavLink}
                  className="nav-link"
                  to="/update-settings"
                >
                  Update Settings
                </Nav.Link>
              </Nav>
            </Can>
            {userAuthentication.isLoggedIn() && (
              <Nav>
                <Nav.Link
                  eventKey="pending"
                  as={NavLink}
                  className="nav-link"
                  to="/pending"
                >
                  Pending scans{" "}
                  <Badge variant="light">{numberOfPendingItems}</Badge>
                </Nav.Link>
              </Nav>
            )}
            <Nav>
              <Nav.Link
                eventKey="help"
                as={NavLink}
                className="nav-link"
                to="/help"
              >
                Help
              </Nav.Link>
            </Nav>
            {userAuthentication.isLoggedIn() && (
              <Nav>
                <Nav.Link
                  eventKey="logout"
                  as={NavLink}
                  className="nav-link"
                  to="/logout"
                >
                  Log Out
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
        <Container fluid className="mt-2">
          <ConnectionAlert
            isConnected={appOnline}
            isServerVisible={isServerVisible}
            pendingScans={pendingScans}
          />
          <UpdateAlert
            role={userAuthentication.role}
            updateFetch={checkForUpdatesState}
            updatesFound={checkForUpdatesResult}
          />
          <Switch>
            <Route path="/login">
              <Login
                appSettings={appSettings}
                authentication={userAuthentication}
                onLogin={doUserLogin}
                onLoginAs={doUserLoginAs}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
              />
            </Route>
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/logout">
              <Logout
                authentication={userAuthentication}
                onLogout={doLogoutUser}
                pendingScans={numberOfPendingItems}
                pendingDeliveries={isPendingDelivery}
              />
            </Route>
            <ProtectedRoute
              path="/collect"
              authentication={userAuthentication}
              acceptedRoles={USER_TYPE.Warehouse}
            >
              <Collect
                appSettings={appSettings}
                isOnline={appOnline}
                isServerAvailable={isServerVisible}
                pendingScans={pendingScans}
                onRequestScanSendOnline={handleQueueCurrentRequestOffline}
                onLabelSent={handleLabelSent}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/loadup"
              authentication={userAuthentication}
              acceptedRoles={[USER_TYPE.Driver, USER_TYPE.Courier]}
            >
              <Loadup
                parcelFetch={fetchTripParcelsState}
                tripFetch={fetchTripState}
                isOnline={appOnline}
                appSettings={appSettings}
                userType={userAuthentication.role}
                parcels={tripParcels}
                parcelScanned={loadupParcelScanned}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
                allScanned={allTripParcelsScanned}
                destinations={dropPoints}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/courier" authentication={userAuthentication}>
              <Pickup
                parcelFetch={fetchTripParcelsState}
                tripFetch={fetchTripState}
                isOnline={appOnline}
                appSettings={appSettings}
                userType={userAuthentication.role}
                parcels={tripParcels}
                parcelScanned={pickupParcelScanned}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/deliver/:dropId"
              authentication={userAuthentication}
            >
              <DeliverParcel
                isOnline={appOnline}
                appSettings={appSettings}
                userType={userAuthentication.role}
                parcels={dropParcels}
                dropPoints={dropPoints}
                dropParcelScanned={handleDropParcelScanned}
                loadDropDetails={loadDropDetails}
                fetchParcels={gatherDropParcels}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
                specialErrorFx={specialErrorScanFx}
                activeDrop={(droppoint: string) => {
                  if (droppoint !== activeDelivery) {
                    updateActiveDelivery(droppoint);
                  }
                }}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/deliver/"
              authentication={userAuthentication}
              acceptedRoles={[USER_TYPE.Driver]}
            >
              <Deliver
                appSettings={appSettings}
                userType={userAuthentication.role}
                dropPoints={dropPoints}
                dropScanned={handleDropScanned}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
                allScanned={allTripParcelsScanned}
                activeDelivery={activeDelivery}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/remove"
              authentication={userAuthentication}
              acceptedRoles={[USER_TYPE.Driver, USER_TYPE.Courier]}
            >
              <Removal
                isOnline={appOnline}
                appSettings={appSettings}
                userType={userAuthentication.role}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/trip-summary"
              authentication={userAuthentication}
              acceptedRoles={[USER_TYPE.Driver, USER_TYPE.Courier]}
            >
              <TripSummary
                status={tripStatus}
                details={tripDetails}
                tripFetch={fetchTripState}
                parcelFetch={fetchTripParcelsState}
                allScanned={allTripParcelsScanned}
                loadupStatus={tripLoadupStatus}
                updateStatus={runUpdateDrops}
                dropsComplete={!isPendingDelivery}
                dropScanned={handleDropScanned}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
                appSettings={appSettings}
                userType={userAuthentication.role}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/pending" authentication={userAuthentication}>
              <Pending
                numberOfPending={numberOfPendingItems}
                pendingScans={pendingScans}
                tripParcels={tripParcels}
                dropParcels={dropParcels}
                isOnline={appOnline}
                requestSend={() => {
                  try {
                    logger.debug("App: Request send scans");
                    requestSync(ServiceWorkerSync.SendScans);
                    logger.debug("App: Request send driver parcels");
                    requestSync(ServiceWorkerSync.SendDriverParcels);
                    logger.debug("App: Request send courier parcels");
                    requestSync(ServiceWorkerSync.SendCourierParcels);
                    logger.debug("App: Request send driver trip");
                    requestSync(ServiceWorkerSync.SendDriverTrip);
                    logger.debug("App: Request send drop parcels");
                    requestSync(ServiceWorkerSync.SendDropParcels);
                    logger.debug("App: Request send complete");
                  } catch (error) {
                    logger.error("App: Request send error occurred", { error });
                  }
                }}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/leave" authentication={userAuthentication}>
              <LeaveParcels
                appSettings={appSettings}
                userType={userAuthentication.role}
                parcels={tripParcels}
                errorFx={errorScanFx}
                scanFx={goodScanFx}
                leaveParcels={doLeaveParcels}
                allScanned={allTripParcelsScanned}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/update-settings"
              authentication={userAuthentication}
            >
              <Update
                automaticUpdateOn={autoUpdate}
                toggleAutomaticUpdate={handleToggleUpdate}
                doUpdateNow={handleDoUpdateNow}
              />
            </ProtectedRoute>
            <DefaultActionRoute path="/" authentication={userAuthentication} />
          </Switch>
        </Container>
      </Router>
    </AbilityContext.Provider>
  );
};

export default App;
