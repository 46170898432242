import { Ability, AbilityBuilder } from "@casl/ability"
import { IUserAuthenitcation } from "../lib/interfaces/IUserAuthentication";
import { USER_TYPE } from "pcd_library";

// Defines how to detect object's type
function subjectName(item: string | any) {
  if (!item || typeof item === "string") {
    return item
  }
  return item.__type
}

const ability = new Ability([], { subjectName })

function defineRulesFor(auth: IUserAuthenitcation) {
    const { can, rules } = AbilityBuilder.extract();
    if (auth.role === USER_TYPE.Warehouse) {
      can('load', 'Item');
    }
    
    if (auth.role === USER_TYPE.Driver || auth.role === USER_TYPE.Courier) {
      can('unload', 'Item');
      can('view', 'Trip');
      can('checkoff', 'Item');
      can('view', 'Summary');
      can('manage', 'Update');
    }

    if (auth.role === USER_TYPE.Driver) {
      can('deliver', 'Item');
      can('leave', 'Item');
    }

    return rules;
}

export const updateUserAbilities = (auth: IUserAuthenitcation) => {
    ability.update(defineRulesFor(auth));
}

export default ability;