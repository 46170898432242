import React from "react";
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import { IUserAuthenitcation } from "../lib/interfaces/IUserAuthentication";
import { USER_TYPE } from "pcd_library";

export interface ProtectedRouteProps extends RouteProps {
  authentication: IUserAuthenitcation;
  acceptedRoles?: USER_TYPE | USER_TYPE[];
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { children, authentication, acceptedRoles, ...routeProps } = props;

  const renderFn = ({ location }: RouteComponentProps<any>) => {
    if (!authentication.isLoggedIn()) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from: location,
            },
          }}
        />
      );
    }

    if (acceptedRoles) {
      // resolve role restrictions
      const rejected = Array.isArray(acceptedRoles)
        ? acceptedRoles.indexOf(authentication.role) === -1
        : acceptedRoles !== authentication.role;
      if (rejected) {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: location,
              },
            }}
          />
        );
      }
    }

    return children;
  };

  return <Route {...routeProps} render={props.render ?? renderFn} />;
};

export default ProtectedRoute;
