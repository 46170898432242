import { getApiConnection } from "./common";
import { CancelToken } from "axios";


export const submitParcel = (headerId: number, sub: number, cancelToken: CancelToken) => {
    const url = 'collection';

    return getApiConnection().post(url, {
        cancelToken,
        headerId,
        sub,
    })
}