import React, { useState } from 'react';
import { USER_TYPE } from 'pcd_library';
import { ChangesState, FetchState } from '../lib/http/common';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type UpdateAlertProps =  {
    role: USER_TYPE;
    updatesFound: ChangesState;
    updateFetch: FetchState;
};

export enum DismissibleAlertState {
    NotShown,
    AutoShown,
    AutoDismiss,
    ManualDismiss,
}

const UpdateAlert: React.FC<UpdateAlertProps> = ({role, updatesFound, updateFetch}) => {
    const [alertShown, setAlertShown] = useState<DismissibleAlertState>(DismissibleAlertState.NotShown);
    
    if (updatesFound === ChangesState.NoChanges && alertShown !== DismissibleAlertState.NotShown) {
        setAlertShown(DismissibleAlertState.NotShown);
    }

    if ([USER_TYPE.Driver, USER_TYPE.Courier].indexOf(role) === -1) {
        return <></>;
    }

    if (updateFetch > FetchState.Init && updatesFound > ChangesState.NoChanges && alertShown < DismissibleAlertState.AutoDismiss) {
        const variant = updatesFound === ChangesState.ChangesFound ? 'warning' : 'success'
        return <Alert variant={variant} dismissible onClose={() => setAlertShown(DismissibleAlertState.ManualDismiss)}>
            {updatesFound === ChangesState.ChangesFound ? <Spinner size="sm" animation="border" /> : <FontAwesomeIcon icon="check-square" size="sm"/>}&nbsp;
            <span>{updatesFound === ChangesState.ChangesFound ? 'Updates found' : 'Updates loaded, please check for new items'}</span>
        </Alert>
    } else {
        return <></>
    }

}

export default UpdateAlert;